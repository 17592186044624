@mixin table-format() {
  > .selection-state {
    padding: 8px 0;
    background-color: $--light-gray;
    text-align: center;

    > a {
      margin-left: 8px;
    }
  }

  > .table-header {
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;

    &.sticky {
      position: absolute;
      left: 0;
      right: 0;
      background-color: $--c6-white;
      // z-index of right pane is 7
      // so a value less than 7 must be used
      z-index: 5;
    }

    .table-header-left {
      display: flex;
      align-items: center;
    }

    .table-header-right {
      display: flex;
      align-items: center;

      > svg {
        width: 30px;
        height: 30px;
        margin-left: 16px;
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: $--c6-blue;

          path {
            stroke: $--c6-white;
          }
        }
      }

      > .export-link {
        width: 30px;
        height: 30px;
        padding: 7px;
        border-radius: 4px;
        color: $--gray;

        &:hover {
          background: $--c6-blue;
          color: $--c6-white;
        }
      }

      > input + .btn,
      > .btn + .btn,
      > .btn + .date-range-component,
      > .date-range-component + .btn,
      > .btn + .rs-dropdown,
      > .rs-dropdown + .date-range-component {
        margin-left: 8px;
      }

      > .date-range-component + .export-link {
        margin-left: 16px;
      }

      .adjust-bid-section {
        display: inline-flex;
        margin-right: 8px;

        .adjust-option-selector {
          min-width: 200px;
          margin-right: 8px;
        }

        > input {
          @include input-format;
          width: auto;
          max-width: 120px;
        }

        .btn {
          margin-left: 8px;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .table-header-left {
        flex-wrap: wrap;

        input {
          width: calc(100% - 18px);
        }

        .date-range-component {
          width: 100%;
          margin: 4px 0px;

          & > div {
            width: 100%;
          }
        }
      }

      .table-header-right {
        margin-left: auto;

        & > svg {
          margin-left: 0 !important;
          margin-right: 16px;
        }
      }
    }
  }

  > .table-body {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;

    &.sticky {
      padding-top: $table-header-height;

      &.has-footer {
        padding-top: $table-header-height * 2;
      }

      &.has-action {
        padding-top: $table-header-height * 2 + 50;
      }

      > .content-header,
      > .content-footer {
        position: absolute;
        // z-index of right pane is 7
        // so a value less than 7 must be used
        z-index: 5;
      }
    }

    .table-row {
      display: inline-flex;
      width: 100%;
      min-height: 70px;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      background-color: white;

      &:hover {
        background-color: rgba(248, 232, 176, 0.25);

        .table-col {
          background-color: rgba(248, 232, 176, 0);
        }
      }

      &.content-header,
      &.content-footer {
        min-height: $table-header-height;
        font-size: 11px;
        font-weight: 700;
        color: $--c6-black;
        background-color: #F9FAFB;

        .table-col {
          background-color: #F9FAFB;

          > span > svg {
            margin-left: 4px;
            vertical-align: middle;
          }
        }
      }

      .table-col {
        display: flex;
        align-items: center;
        height: 70px;
        padding-left: 4px;
        padding-right: 4px;
        border-bottom: 1px solid #ddd;

        &:first-child {
          padding-left: 28px;
        }

        &.col-check {
          flex: 0 0 50px;
        }

        &.click {
          cursor: pointer;
        }

        &.col-history {
          &:hover {
            .tooltiptext {
              visibility: visible;
            }
          }

          .recharts-responsive-container {
            margin-top: 2px;

            .recharts-wrapper {
              cursor: pointer!important;

              svg {
                width: 100%;
                height: 75%;
              }
            }
          }

          .tooltiptext {
            position: absolute;
            left: 75%;
            bottom: 60%;
            width: 25px;
            background-color: $--c6-blue;
            border-radius: 4px;
            color: $--c6-white;
            opacity: 0.8;
            text-align: center;
            visibility: hidden;
            z-index: 1;

            &::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -4px;
              border-width: 4px;
              border-style: solid;
              border-color: $--c6-blue transparent transparent transparent;
            }
          }
        }
      }
    }
  }
}

@mixin sortable-table-format {
  @include table-format();

  .table-row.content-header {
    .table-col {
      cursor: pointer;

      > svg {
        margin-left: 4px;
      }
    }
  }
}

@mixin campaign-name-cell {
  .col-campaign {
    .campaign-detail {
      margin-top: 4px;
      font-size: 11px;
      color: $--dark-gray;

      span + span {
        margin-left: 8px;
      }
    }
  }
}

@mixin group-table-format {
  @include sortable-table-format();

  .table-col {
    flex: 1;

    &:not(.col-expand):not(.col-check) {
      min-width: 120px;
    }

    &.col-expand {
      flex: 0 0 64px;
      text-align: center;

      .expand-icon {
        display: inline-block;
        width: 20px;
        border: 1px solid $--light-gray;
        cursor: pointer;
      }
    }
  }

  .child-table-wrapper {
    padding-left: 64px + 160px;

    .custom-table-component {
      @include sortable-table-format();
    }
  }

  .table-row:not(.content-header) {
    @include campaign-name-cell;

    .col-campaign {
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: center;

      .contents {
        width: 100%;
      }
    }
  }
}

@mixin compact-header-table-format {
  .table-row.content-header,
  .table-row.content-footer {
    .table-col {
      height: 48px;
    }
  }

  .child-table-wrapper {
    .custom-table-component {
      .table-row.content-header,
      .table-row.content-footer {
        .table-col {
          height: 48px;
        }
      }
    }
  }
}

.custom-table-component {
  position: relative;

  &.loading {
    opacity: 0.5;
  }
}