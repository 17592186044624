.campaign-detail-negative-op {
  .table-search-words {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &.col-word {
        flex: 2;
      }
    }
  }
}