.report-header {
  .report-logo-section {
    display: flex;
    align-items: center;
  }

  .upload-helper-text {
    border: 1px dashed #ccc;
    padding: 10px;
  }

  .brand-logo-upload {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .rs-uploader-file-items {
      .rs-uploader-file-item {
        width: 230px;
        height: 100px;
        margin: 0;
        background: none;
        border: 1px solid #ddd;

        .rs-uploader-file-item-preview {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 230px;
          height: 100px;

          img {
            display: block;
            max-width: 230px;
            max-height: 100px;
            width: auto;
            height: auto;
          }
        }
      }
    }

    .rs-uploader-trigger-btn {
      width: 36px;
      height: 36px;
      margin-top: 0;
    }
  }

  .saved-report-brand-logo {
    display: flex;
    width: 230px;
    height: 100px;
    align-items: center;
    justify-content: center;
    border: 1px dashed #ddd;

    img {
      display: block;
      max-width: 230px;
      max-height: 100px;
      width: auto;
      height: auto;
    }
  }

  .upload-save-btn {
    margin-left: 4px;
  }

  .remove-brand-logo-btn {
    border: 1px dashed #ddd;
    margin-left: 4px;
  }

  .logo-remove-btn {
    border: 1px dashed #ddd;
    margin-left: 4px;
  }

  .report-date {
    text-align: right;

    .account-report-date {
      display: flex;
      justify-content: flex-end;
      column-gap: 8px;
      margin-bottom: 16px;

      .date-range-container {
        min-width: 200px;

        .rs-picker-date {
          width: 100%;
        }
      }

      .rs-dropdown {
        a {
          color: #757575;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .report-helper-text {
    color: #888;
    font-size: 12px;
    text-align: right;
  }

  .account-meta-info {
    .account-brand-name {
      display: flex;

      .new-brand-name-input {
        width: 150px;
        margin: 0 5px;
      }
    }
  }
}

.account-overview-section {
  position: relative;
  margin-top: 16px;

  .report-note-helper-text {
    padding-top: 5px;
    text-align: right;
    font-size: 11px;
    font-style: italic;
    color: #888;
  }

  .keywords-track-section {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 16px;

    > label {
      font-weight: 700;
    }

    .keywords-list {
      .keyword-wrapper {
        display: inline-flex;
        align-items: center;
        margin: 4px;
        padding: 4px 8px;
        background-color: #f8f8f8;
        border-radius: 4px;
        font-size: 12px;
      }
    }
  }
}

.report-widget {
  background: white;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
  -webkit-box-shadow: 5px 7px 9px -6px rgba(0,0,0,.41);
  box-shadow: 5px 7px 9px -6px rgba(0,0,0,.41);
  min-height: 150px;

  .report-widget-header {
    display: flex;
    justify-content: space-between;
    height: 40px;

    .widget-title {
      display: flex;
      align-items: center;
      font-size: 16px;

      > button {
        margin-right: 8px;
      }

      & > svg {
        margin-left: 8px;
      }

      .highlight-report-widget {
        background: none;
        color: #c1c1c1;

        &.active {
          color: gold;
        }
      }
    }

    .widget-action {
      .type-selector-container {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        padding: 0 16px;

        .btn + .btn {
          margin-left: 16px;
        }
      }
    }

    .rs-btn-violet {
      color: #575757;
      background-color: #e5e5ea;

      &:not(.rs-btn-disabled):hover {
        background-color: #e5e5ea;
      }
    }
  }

  .report-widget-content {
    &.stream-content {
      margin-top: 8px;
      overflow: auto;

      .section {
        margin-bottom: 32px;
        background: #fff;
        box-shadow: $card-box-shadow;

        .section-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 25px 0;

          h4 {
            font-size: 16px;
          }
        }

        .stream-data-container {
          position: relative;
          min-height: 96px;
          padding: 18px 24px;

          .custom-table-component {
            @include table-format;

            .table-col {
              flex: 1;

              &.col-keyword {
                flex: 0 0 160px;
                flex-direction: column;
                align-items: flex-start!important;
                justify-content: center;

                .meta-data {
                  margin-top: 4px;
                  font-size: 11px;
                  color: $--dark-gray;
                }
              }
            }
          }

          .table-target-stream-view {
            @include group-table-format;

            .child-table-wrapper {
              padding-left: 200px;
            }
          }
        }

        .stream-header-wrapper {
          display: flex;
          align-items: center;

          > svg {
            margin-left: 8px;
          }
        }

        .mode-container {
          display: flex;
          align-items: center;

          .date-range-component {
            margin-right: 16px;
          }

          .rs-btn-toggle {
            margin-left: 16px;
          }
        }
      }
    }

    .widget-value {
      font-size: 30px;
      text-align: center;
    }

    .widget-prev-value {
      font-size: 15px;
      text-align: center;

      span {
        display: inline-block;
        padding: 0px 10px;
        min-width: 100px;
        border-radius: 10px;

        &.positive-trending {
          background-color: #00ff004a;
          color: #00a100;
        }

        &.negative-trending {
          background-color: rgba(250,92,124,.18);
          color: #fa5c7c;
        }

        &.neutral-trending {
          background-color: #e1dc324a;
          color: #dbaf08;
        }
      }
    }

    .performance-item {
      font-weight: 600;

      .performance-main-metric {
        font-size: 16px;
      }

      .performance-main-metric-value {
        padding-top: 10px;
        font-size: 20px;
      }

      .performance-progress {
        padding-top: 10px;

        .label {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
        }

        .content {
          padding-top: 5px;
        }
      }
    }

    .create-new-metric-btn {
      display: flex;
      flex-direction: column;
      height: 108px;
      justify-content: center;
      align-items: center;
      border: 1px dashed #eee;
    }

    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 84px;

      .new-metric-title {
        font-size: 16px;
      }

      .metric-selector {
        width: 100%;
        margin-top: 5px;
      }

      .new-metric-formula {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        margin-top: 5px;

        span {
          font-weight: 700;
        }

        .operator-selector {
          width: 64px;
        }
      }
    }

    .step-action-btn {
      display: flex;
      justify-content: space-between;
    }

    .table-performance-segment {
      @include sortable-table-format;

      .table-col {
        flex: 1;

        &.col-label {
          flex: 0 0 120px;
        }
      }
    }
  }

  .report-chart-widget-content {
    height: 300px;
  }

  .table-report {
    @include table-format;

    .table-col {
      flex: 1;

      &:first-child {
        flex: 1 1 180px;
      }

      .product-col {
        display: flex;
        align-items: center;

        .product-thumbnail {
          width: 50px;
          height: 50px;

          &>img {
            width: 100%;
            height: 100%;
          }
        }

        .product-asin {
          margin-left: 10px;
        }
      }

      .target-text-wrapper {
        display: flex;
        align-items: center;

        > strong {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        > a {
          margin-left: 4px;
        }
      }
    }

    .table-body {
      .table-row {
        height: 65px;
      }
    }
  }

  .group-table-report {
    @include group-table-format;

    .table-col {
      &.col-campaign,
      &.col-target {
        flex: 0 0 160px;

        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-keyword {
        flex: 0 0 160px;
      }
    }
  }
}

.section-header {
  .section-header-title {
    display: flex;

    .section-title {
      font-size: 24px;
    }

    .section-show-btn {
      margin-left: 8px;
    }
  }

  .section-actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;

    .btn {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }
}

.report-sidebar-container {
  position: fixed;
  // Height of the Carbon6 navbar is 48px
  // Height of the top header is 64px
  top: 48px + 64px + 32px;
  right: 48px;
  height: calc(100% - 48px - 64px - 32px);
  overflow: auto;

  .rs-nav-item {
    &:not(:first-child) {
      margin-top: 0px !important;
    }

    .rs-nav-item-content {
      padding: 10px 12px
    }
  }
}

.generated-reports-modal {
  @include modal-format;

  .table-jobs {
    @include table-format;

    .table-header-right {
      flex: 1;
      justify-content: space-between;
    }

    .table-col {
      flex: 1;

      .rs-btn + .rs-btn {
        margin-left: 8px;
      }
    }

    .table-pagination {
      @include pagination-compact;
    }
  }
}

.keyword-track-input-modal {
  input[type=file] {
    display: none;
  }

  .description-box {
    margin-bottom: 4px;

    a {
      display: inline-flex;
      align-items: center;
    }

    svg {
      margin-left: 4px;
    }
  }

  textarea {
    width: 100%;
    min-height: 240px;
    padding: 10px 12px;
    border: 1px solid $--light-gray;
    border-radius: 6px;
    resize: none;
  }
}
