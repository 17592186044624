.bulk-expansion-container {
  .section-label {
    margin: 8px 16px;
    font-size: 16px;
  }

  .table-targets,
  .table-st-selection,
  .table-search-terms,
  .table-asins,
  .table-categories {
    @include sortable-table-format;

    .table-header-right {
      .btn-new {
        padding-top: 7px;
        padding-bottom: 7px;
        color: $--c6-white;
        background-color: $--button-green;
      }
    }

    .table-col {
      flex: 1;
    }
  }

  .table-targets {
    .table-col {
      &.col-target {
        a {
          margin-left: 8px;
        }
      }
    }
  }

  .table-search-terms,
  .table-asins,
  .table-categories {
    .table-col {
      &:not(.col-check) {
        min-width: 80px;
      }
    }

    .table-row:not(.content-header) {
      @include campaign-name-cell;

      .col-campaign {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .contents {
          width: 100%;
        }
      }
    }
  }

  .table-search-terms,
  .table-asins,
  .table-categories,
  .table-grouped-asins,
  .table-grouped-search-terms {
    .table-col {
      &.col-keyword,
      &.col-product,
      &.col-campaign,
      &.col-category {
        .contents {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .contents + a {
          margin-left: 8px;
        }
      }

      &.col-keyword {
        flex: 0 0 120px;
      }

      &.col-category {
        flex: 0 0 120px;

        > a {
          margin-left: 4px;
        }
      }

      &.col-search-term {
        flex: 0 0 160px;

        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        > a {
          margin-left: 8px;
        }
      }

      &.col-product {
        flex: 0 0 160px;

        img {
          width: 32px;
        }

        img + .contents {
          margin-left: 4px;
        }
      }

      &.col-asin {
        flex: 0 0 120px;

        .contents + a {
          margin-left: 4px;
        }

        > svg {
          margin-left: 8px;
        }
      }

      &.col-campaign {
        flex: 0 0 160px;
      }
    }
  }

  .table-grouped-asins,
  .table-grouped-search-terms {
    @include group-table-format;
  }
}

.target-ex-input-modal {
  input[type=file] {
    display: none;
  }

  .description-box {
    margin-bottom: 4px;

    a {
      display: inline-flex;
      align-items: center;
    }

    svg {
      margin-left: 4px;
    }
  }

  textarea {
    width: 100%;
    min-height: 240px;
    padding: 10px 12px;
    border: 1px solid $--light-gray;
    border-radius: 6px;
    resize: none;
  }
}

.target-add-modal {
  .table-content {
    position: relative;

    &.loading {
      opacity: .5;
    }
  }

  .table-campaigns,
  .table-adgroups,
  .table-bids {
    @include sortable-table-format;
    @include campaign-name-cell;

    &.table-bids {
      .table-body {
        overflow: visible;
      }
    }

    .table-col {
      flex: 1;

      &.col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;
      }

      &.col-bid {
        input {
          @include input-format;
        }
      }

      .match-type-selector {
        flex: 1;
      }
    }

    .campaign-status {
      font-size: 10px;
      margin-bottom: 4px;

      .status {
        display: flex;
        align-items: center;
        margin-right: 8px;

        .bullet {
          display: inline-block;
          width: 4px;
          height: 4px;
          margin-right: 4px;
          border-radius: 50%;
        }

        &.on {
          span {
            color: #2EB73C;
          }

          .bullet {
            background: #2EB73C;
          }
        }

        &.off {
          span {
            color: $--accent;
          }

          .bullet {
            background: #DDADAA;
          }
        }
      }
    }

    .campaign-name {
      margin-bottom: 4px;
    }
  }

  .table-campaigns {
    .filter-wrapper {
      display: flex;
      align-items: center;

      > label {
        margin-right: 8px;
      }

      .select-wrapper {
        min-width: 200px;
      }
    }

    .filter-wrapper + .filter-wrapper {
      margin-left: 16px;
    }
  }

  .bid-action-container {
    display: flex;
    margin-top: 8px;

    .bid-action-wrapper {
      flex: 1;

      .bid-action-control {
        display: flex;
        margin-top: 4px;

        .match-type-selector,
        .lookback-selector {
          width: 240px;
        }

        .match-type-selector__menu {
          // z-index of input box is 4.
          z-index: 5;
        }

        > input {
          @include input-format;
          max-width: 240px;
        }

        > .btn {
          min-height: 38px;
          margin-left: 4px;
        }

        .checkbox-wrapper {
          display: flex;
          align-items: center;
          flex: 1;
          margin-top: 8px;

          > svg {
            margin-left: 4px;
          }
        }
      }
    }

    .bid-action-wrapper + .bid-action-wrapper {
      margin-left: 16px;
    }
  }

  .bid-action-container + .bid-action-container{
    margin-top: 16px;
  }
}

.adgroup-creator-modal {
  &.loading {
    opacity: 0.5;
  }

  .rs-modal-body {
    position: relative;
    margin-top: 0;
  }

  .campaign-option {
    .campaign-detail {
      margin-top: 4px;
      font-size: 11px;
      color: $--dark-gray;

      span + span {
        margin-left: 8px;
      }
    }
  }
}
