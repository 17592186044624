.product-detail-component {
  @include page-layout;

  &.loading {
    opacity: 0.5;
  }

  .page-header {
    position: relative;
    margin-bottom: 24px;

    .page-title-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .page-title {
      position: relative !important;
      display: flex;
      align-items: center;
      width: calc(100% - 500px);
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .button-wrapper {
      min-width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .tutorial-link {
        margin-left: 16px;
      }
    }
  }

  .product-filter-component {
    position: absolute;
    width: 700px;
    top: 40px;
    background: $--c6-white;
    border-radius: 4px;
    box-shadow: $card-box-shadow;
    overflow: hidden;
    z-index: 6;

    .table-product-selector {
      @include table-format;

      .table-col {
        flex: 1;

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        span.no-image {
          background-color: $--yellow;
          margin-left: 40px;
        }
      }
    }
  }

  .page-tabs {
    display: flex;
    margin-bottom: 8px;
    color: $--gray;

    @media (max-width: 576px) {
      flex-wrap: wrap;
    }

    .page-tab {
      margin-right: 28px;
      padding-bottom: 13px;
      background-color: transparent;
      border-bottom: 2px solid transparent;

      &.selected,
      &:hover {
        color: $--c6-black;
        border-bottom: 2px solid $--c6-black;
      }
    }
  }
}