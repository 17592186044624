.search-box-component {
  display: flex;
  align-items: center;

  .rs-input-group-addon {
    padding-top: 10px !important;
    padding-bottom: 9px !important;
  }

  .rs-input {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 13px;
    // Use same color with the neighbor controls
    border: 1px solid $--light-gray;

    &:hover {
      border: 1px solid #B3B3B3;
    }
  }

  &:hover {
    .search-inclusive-btn {
      border-color: #B3B3B3;
    }
  }

  .search-inclusive-btn {
    margin-left: -5px;
    min-height: 36.56px;
    border: 1px solid $--light-gray;
    border-left: none;
    background: $--c6-white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: border-color ease-in-out 0.3s;
    z-index: 4;
  }
}
