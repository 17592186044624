.date-range-component {
  position: relative;
  display: inline-block;

  .toggle-element {
    position: relative;
    display: inline-block;
    padding: 7px 42px 7px 12px;
    border: 1px solid $--light-gray;
    border-radius: 6px;
    color: $--c6-black;
    text-decoration: none;
    transition: border-color .3s ease-in-out;
    cursor: pointer;
    z-index: 5;

    &.active {
      border-color: #1675e0;
    }

    &.disabled {
      opacity: .65;
      cursor: not-allowed;
    }

    .toggle-caret {
      position: absolute;
      top: 8px;
      right: 12px;
      color: #8e8e93;

      &::before {
        content: "\f073"!important;
        font-family: "rsuite-icon-font"!important;
      }
    }
  }

  .picker-popup {
    position: absolute;
    display: flex;
    left: 0;
    top: 36px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
    z-index: 8;

    &.active {
      width: 510px + 120px;
      height: 370px;
    }

    &.bottom-end {
      left: unset;
      right: 0;
    }

    .range-container {
      flex: 0 0 120px;
      padding: 8px;
      border-right: 1px solid $--light-gray;

      a {
        display: block;
        padding: 4px;
        border-radius: 4px;
        font-size: 12px;
        color: $--c6-blue;
        text-decoration: none;

        &:hover {
          background: #b4dff6;
        }

        svg {
          margin-left: 8px;
          vertical-align: middle;
        }
      }

      a + a {
        margin-top: 4px;
      }
    }

    .rs-picker-daterange {
      display: none;
    }

    .rs-picker-menu {
      position: static;
      box-shadow: none;
    }
  }
}
