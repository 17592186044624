$--c6-black: #000000;
$--c6-dark-purple: #1A172E;
$--c6-purple: #2E0077;
$--c6-light-purple: #5B22E5;
$--c6-pink: #FF00F9;
$--c6-bright-green: #AFFF00;
$--c6-blue: #303AB2;
$--c6-light-blue: #1AFFEE;
$--c6-white: #FFFFFF;
$--c6-gray: #667085;

$--gray: #979797;
$--light-gray: #D9D9D9;
$--dark-gray: #606060;
$--soft-gray: #687A90;
$--accent: #EA305C;
$--red: #FF3A2E;
$--soft-white: #FCFCFD;

$--button-green: #32b83f;
$--green: #93DF8D;
$--yellow: #FFD156;
$--dark-red: #FF7B56;
$--dark-pink: #FFA7D9;
$--soft-blue: #AACAFB;
$--light-blue: #80A4FF;

$table-header-height: 48px;

$card-box-shadow: 0px 1px 3px rgba(117, 117, 117, 0.25);
