.sp-campaign-creator,
.sd-campaign-creator,
.sb-campaign-creator {
  @include page-layout;

  &.loading {
    opacity: 0.5;
  }

  .page-content {
    margin-bottom: 24px;
    background-color: $--c6-white;
    border-radius: 4px;
    box-shadow: $card-box-shadow;
  }

  .page-footer {
    text-align: right;
  }
}

.product-modal,
.keyword-modal,
.product-targeting-modal,
.negative-targeting-modal,
.audience-modal {
  &.loading {
    opacity: 0.5;
  }

  .tab-list {
    padding: 16px 24px 0;
    border-bottom: 1px solid $--light-gray;

    button {
      display: inline-flex;
      align-items: center;
      margin-right: 16px;
      padding-bottom: 16px;
      background-color: transparent;
      color: $--gray;

      &.selected,
      &:hover {
        border-bottom: 2px solid $--c6-black;
        color: $--c6-black;
      }

      svg {
        margin-left: 4px;
      }
    }
  }

  .sub-tab-list {
    margin-top: 8px;
    padding: 8px 24px 0;
    border-bottom: 1px solid $--light-gray;

    button {
      display: inline-flex;
      align-items: center;
      margin-right: 16px;
      padding-bottom: 8px;
      background-color: transparent;
      color: $--gray;

      &.selected,
      &:hover {
        border-bottom: 2px solid $--c6-black;
        color: $--c6-black;
      }
    }
  }

  .asin-list,
  .keyword-list {
    @include input-format;
    height: auto;
    margin-top: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.product-modal,
.product-targeting-modal,
.negative-targeting-modal {
  @include modal-format;

  .table-adgroups {
    @include table-format;
    @include campaign-name-cell;

    .table-col {
      flex: 1;

      &.col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;
      }
    }
  }

  .table-products {
    @include table-format;

    .table-col {
      &:only-child {
        flex: 1;
      }
    }

    .col-product {
      display: flex;
      flex: 1;
      min-width: 0;
      justify-content: center;

      img {
        width: 48px;
        height: 48px;
      }

      .image-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 1px solid $--light-gray;
        border-radius: 4px;
        font-size: 12px;
        color: $--gray;
        text-align: center;
      }

      .product-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        min-width: 0;
        margin-left: 16px;
        margin-right: 16px;

        .product-name {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .product-asin-info {
          margin-top: 4px;
          font-size: 12px;
          color: $--gray;
        }
      }
    }

    .table-pagination {
      @include pagination-compact;
    }
  }
}

.keyword-modal,
.target-acos-modal,
.daily-budget-modal,
.targets-view-modal,
.negative-keywords-view-modal {
  @include modal-format;

  .table-keywords,
  .table-budget-rules {
    @include table-format;
    @include campaign-name-cell;

    .table-header-right {
      label {
        margin-right: 8px;
      }

      input {
        @include input-format;
        max-width: 120px;
      }
    }

    .table-col {
      flex: 1;

      &.col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .contents {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      input {
        @include input-format;
      }
    }
  }
}

.keyword-modal {
  .match-type-option-list {
    display: flex;
    margin-top: 16px;

    .checkbox-component + .checkbox-component {
      margin-left: 16px;
    }
  }
}

.targets-view-modal {
  .adgroup-selector {
    .btn + .btn {
      margin-left: 8px;
    }
  }
}

.product-targeting-modal,
.audience-modal {
  .match-selector {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding-left: 24px;

    .match-title {
      margin-right: 16px;
    }
  }

  .category-tab-container {
    display: flex;
    padding: 0 8px;
  }

  .category-table-container {
    flex: 1;
    min-width: 0;
  }

  .category-tree-container {
    position: relative;
    flex: 1;
    min-width: 0;
    margin-left: 16px;

    &.loading {
      opacity: 0.5;
    }

    .search-box-wrapper {
      display: flex;
      align-items: center;
      padding: 16px 24px;

      input {
        font-size: 13px;
        padding-left: 10px;
        border: 0;
      }
    }
  }

  .table-categories {
    @include table-format;

    > .table-body {
      .table-row {
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
      }
    }

    .table-col {
      &:only-child {
        flex: 1;
      }

      &.col-category {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;
        flex: 1;
        min-width: 0;

        .category-path {
          width: 100%;
          margin-bottom: 4px;
          font-size: 12px;
          color: $--gray;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .btn + .btn {
        margin-left: 4px;
      }
    }

    .table-pagination {
      @include pagination-compact;
    }
  }

  .product-tab-container {
    position: relative;

    &.loading {
      opacity: 0.5;
    }

    .asin-list {
      @include input-format;
      height: auto;
      margin-top: 16px;
      margin-bottom: 8px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.negative-targeting-modal {
  .table-brands {
    @include table-format;

    .table-col {
      &:only-child {
        flex: 1;
      }
    }

    .col-brand {
      flex: 1;
      justify-content: center;
    }

    .table-pagination {
      @include pagination-compact;
    }
  }
}

.refine-modal {
  .rs-modal-title {
    display: flex;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }

  .rs-modal-body {
    padding-left: 8px;
    padding-right: 8px;
  }

  .field-row {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;

    .field-wrapper {
      flex: 1;
      margin: 0 8px;
      min-width: 0;

      .field-name {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        strong {
          font-weight: 600;
        }

        > svg {
          margin-left: 8px;
        }
      }

      .price-range-input {
        input {
          @include input-format;
          display: inline-block;
          width: auto;
        }

        span {
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      .rs-slider {
        margin: 16px;
      }
    }
  }

  .field-row + .field-row {
    margin-top: 16px;
  }
}

.audience-modal {
  .views-product-container {
    .view-item {
      display: flex;
      height: 70px;
      padding: 0 24px;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;
      font-size: 13px;
      font-weight: 400;

      &:hover {
        background-color: rgba(248, 232, 176, 0.25);
        cursor: pointer;
      }

      .item-label {
        display: flex;
        flex: 1;
        align-items: center;

        > svg {
          margin-left: 4px;
        }
      }
    }
  }

  .audience-tree {
    margin-top: 16px;

    .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
      height: 44px;
      transform-origin: 3.5px 20px;
    }

    .rs-tree-node-custom-icon {
      svg {
        margin-top: 14px;
      }
    }

    .rs-tree-node-label,
    .rs-tree-node-label-content {
      display: block;
      min-height: 44px;
    }

    .audience-tree-node {
      display: flex;
      align-items: center;
      min-height: 28px;

      .label-wrapper {
        display: flex;
        flex: 1;
        align-items: center;

        svg {
          margin-left: 8px;
        }
      }

      .btn {
        font-size: 13px;
      }
    }
  }
}

.creator-section {
  .section-container {
    padding: 24px;

    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;

      .btn {
        font-weight: normal;
      }

      .btn + .btn {
        margin-left: 8px;
      }
    }

    .section-note {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: $--gray;
    }

    .section-title + .field-row,
    .section-title + .checkbox-component,
    .section-note + .field-row {
      margin-top: 16px;
    }

    .field-row {
      display: flex;
      margin-left: -8px;
      margin-right: -8px;

      .field-wrapper {
        flex: 1;
        margin: 0 8px;
        min-width: 0;

        .field-name {
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          strong {
            font-weight: 600;
          }

          > svg {
            margin-left: 8px;
          }
        }

        > input,
        .input-wrapper > input {
          @include input-format;

          &.shrinked-input {
            max-width: 128px;
          }
        }

        .input-prefix + input,
        input + .input-suffix {
          margin-left: 4px;
        }

        .input-wrapper {
          display: inline-block;
        }

        .input-wrapper + .input-wrapper {
          margin-left: 16px;
        }
      }
    }

    .field-row + .field-row {
      margin-top: 16px;
    }

    .portfolio-selector__menu {
      // z-index of datepicker is 5.
      z-index: 6;
    }

    .rs-radio-checker {
      > label {
        display: inline-flex;

        > svg {
          margin-left: 8px;
        }
      }
    }

    .strategy-option {
      .strategy-desc {
        margin-top: 2px;
        font-size: 12px;
        color: #aaa;
      }
    }
  }

  .section-container + .section-container {
    border-top: 1px solid $--light-gray;
  }

  .product-container {
    margin-top: 16px;
    border: 1px solid $--light-gray;

    .product-box {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 32px 8px 16px;

      .close-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
        color: $--gray;
        cursor: pointer;
      }

      img {
        max-width: 72px;
        margin-right: 16px;
      }

      .product-info {
        .product-name {
          margin-bottom: 8px;
        }

        .product-detail {
          span {
            font-size: 13px;
            color: $--gray;
          }

          span + span {
            margin-left: 16px;
          }
        }
      }
    }

    .product-box + .product-box {
      border-top: 1px solid $--light-gray;
    }
  }

  .default-bid-section {
    display: flex;
    align-items: center;

    input {
      @include input-format();
      display: inline-block;
      width: auto;
      max-width: 96px;
      margin: 0 8px;
    }
  }

  .default-match-type-section {
    display: flex;
    align-items: center;

    .match-type-selector {
      min-width: 120px;
      margin: 0 8px;
    }
  }

  .suggested-bid-type-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > svg {
      margin-left: 4px;
    }

    .bid-type-selector {
      margin: 0 8px;
      width: 150px;
    }
  }

  .no-product-desc,
  .no-keyword-desc,
  .no-targeting-desc {
    margin-top: 16px;
    color: $--gray;
    text-align: center;
  }

  .keyword-container {
    margin-top: 16px;
    border: 1px solid $--light-gray;

    .keyword-box {
      padding: 8px 16px;
      border-top: 1px solid $--light-gray;

      &:nth-child(1) {
        border: 0;
      }

      .box-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .match-selector {
          display: flex;
          align-items: center;

          > label {
            margin-right: 8px;
          }

          .match-select__control {
            width: 200px;
          }
        }

        .apply-section {
          display: flex;
          align-items: center;

          .btn + .btn {
            margin-left: 8px;
          }
        }
      }

      .box-content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        input {
          @include input-format;
          display: inline-block;
          width: 96px;
        }
      }

      .box-header + .box-content {
        margin-top: 16px;
        padding-right: 48px;
      }

      .close-icon {
        font-size: 24px;
        color: $--gray;
        cursor: pointer;
      }
    }
  }

  .keyword-input-section {
    margin-top: 8px;

    .match-type-container {
      display: flex;
      align-items: center;

      > label {
        margin-right: 8px;
      }
    }

    .match-select__control {
      width: 200px;
    }
  }

  .btn-add-keyword {
    margin-top: 16px;
  }

  .targeting-list {
    margin-top: 16px;

    .targeting-item {
      display: flex;
      align-items: center;
      padding: 8px 16px;

      .targeting-info {
        flex: 1;

        .category-path {
          margin-bottom: 4px;
          font-size: 12px;
          color: $--gray;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .targeting-meta {
          margin-top: 4px;
          font-size: 12px;
          color: $--gray;
        }
      }

      .suggested-bid-container {
        margin-right: 8px;

        .apply-section {
          display: flex;
          align-items: center;
          margin-top: 4px;

          .btn + .btn {
            margin-left: 8px;
          }
        }
      }

      .targeting-action {
        display: flex;
        align-items: center;

        input {
          @include input-format;
          display: inline-block;
          width: 96px;
        }

        .close-icon {
          margin-left: 8px;
          font-size: 24px;
          color: $--gray;
          cursor: pointer;
        }
      }
    }

    .targeting-item + .targeting-item {
      border-top: 1px solid $--light-gray;
    }
  }

  .targeting-type-selector {
    .selector-wrapper {
      display: flex;
      margin-top: 16px;

      .selector {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 24px;
        border: 1px solid $--light-gray;
        cursor: pointer;

        &:hover,
        &.selected {
          background-color: rgba(248, 232, 176, 0.22);
          border: 1px solid rgba(251, 171, 52, 0.5);

          svg {
            stroke: $--c6-blue;

            path {
              stroke: $--c6-blue;
            }
          }

          .selector-note {
            color: #858383;
          }
        }

        svg {
          margin-right: 16px;
          stroke-width: 3px;
        }

        .selector-note {
          font-size: 13px;
          font-style: italic;
          color: $--gray;
        }
      }
    }
  }

  textarea {
    @include input-format;
    height: auto;
    margin-top: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .location-area {
    position: relative;
    display: flex;
    margin-top: 16px;

    .location-pane {
      flex: 1;
    }

    .location-pane + .location-pane {
      margin-left: 16px;
    }

    .table-locations {
      @include table-format;

      .table-col {
        min-width: 72px;

        &:only-child {
          flex: 1;
        }

        &.col-location {
          flex: 1;
          flex-direction: column;
          align-items: flex-start!important;
          justify-content: center;

          .location-category {
            margin-top: 4px;
            font-size: 12px;
            color: $--gray;
          }
        }

        .location-add-status {
          font-size: 12px;
          color: $--gray;
          font-style: italic;
        }

        .close-icon {
          font-size: 24px;
          color: $--gray;
          cursor: pointer;
        }
      }
    }

    .search-box-component + .table-locations {
      margin-top: 8px;
    }
  }

  .warning-message {
    margin-top: 4px;
    font-size: 12px;
    color: $--red;
  }

  .creative-section {
    position: relative;

    &.loading {
      opacity: 0.5;
    }

    .field-row {
      margin-top: 16px;
    }

    .customizer-container {
      .customizer-section {
        .field-section-name {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          > svg {
            margin-left: 8px;
          }
        }

        .field-section-contents {
          > input {
            @include input-format;
          }

          .brand-logo-container,
          .crop-square-section {
            .brand-logo-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 96px;
              height: 96px;
              padding: 8px;
              border: 1px solid $--light-gray;

              img {
                max-width: 72px;
              }
            }

          }

          .brand-logo-container {
            display: flex;

            .action-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 8px;

              .btn + .btn {
                margin-top: 8px;
              }
            }

            input[type=file] {
              display: none;
            }
          }

          .crop-square-section {
            margin-top: 16px;

            .crop-square-title {
              display: flex;
              align-items: center;

              > svg {
                margin-left: 8px;
              }
            }

            .brand-logo-wrapper {
              margin: 8px 0;
            }
          }

          &.creative-video-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            input[type=file] {
              display: none;
            }

            .btn + video {
              margin-top: 8px;
            }
          }
        }
      }

      .customizer-section + .customizer-section {
        margin-top: 16px;
      }
    }

    .field-name + .customizer-container {
      margin-top: 16px;
    }

    .sb-preview-container {
      margin-top: 16px;
    }

    .sd-preview-container {
      margin-top: 16px;

      .preview-contents {
        margin-top: 8px;
        padding: 8px 16px;
        border: 1px solid $--light-gray;

        .headline,
        .product-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-break: break-all;
          overflow: hidden;
        }

        .headline {
          font-weight: 700;
        }

        .image-wrapper {
          text-align: center;
        }

        .product-name {
          -webkit-line-clamp: 2;
        }

        .btn-shop {
          @include button-variant(#ffa41c, #ffa41c, #fff, $hover-color: #fff, $active-color: #fff, $disabled-color: #fff);
          border-radius: 17px;
        }
      }

      .preview-page-right,
      .preview-page-middle,
      .preview-mobile-rect,
      .preview-mobile-leaderboard,
      .preview-wide,
      .preview-large-rect,
      .preview-stripe {
        display: inline-flex;
      }

      .preview-leaderboard,
      .preview-billboard {
        display: flex;
      }

      .preview-page-middle,
      .preview-mobile-rect,
      .preview-mobile-leaderboard,
      .preview-wide,
      .preview-leaderboard,
      .preview-billboard,
      .preview-large-rect,
      .preview-stripe {
        align-items: center;
      }

      .preview-page-right,
      .preview-wide,
      .preview-large-rect {
        flex-direction: column;
      }

      .preview-page-right {
        max-width: 240px;

        .image-wrapper {
          display: inline-flex;
          width: 100%;

          .image-slot {
            display: inline-flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;

            img {
              max-width: 100%;
            }

            .headline {
              -webkit-line-clamp: 3;
            }

            img + .headline {
              margin-top: 8px;
            }
          }

          .image-slot + .image-slot {
            margin-left: 8px;
          }
        }

        .info-wrapper {
          margin-top: 8px;

          .headline {
            -webkit-line-clamp: 2;
          }
        }
      }

      .preview-page-middle {
        .image-wrapper {
          display: inline-flex;
          align-items: center;

          .image-slot {
            img {
              max-width: 120px;
            }

            .headline {
              -webkit-line-clamp: 3;
              min-width: 120px;
            }
          }

          .image-slot + .image-slot {
            margin-left: 8px;
          }
        }

        .info-wrapper {
          margin-left: 16px;
        }
      }

      .preview-mobile-rect {
        max-width: 480px;

        .image-wrapper {
          margin-right: 16px;

          .image-slot {
            img {
              max-width: 160px;
            }
          }
        }

        .info-wrapper {
          .headline {
            -webkit-line-clamp: 1;
          }

          .headline + .product-name {
            margin-top: 4px;
            -webkit-line-clamp: 1;
          }
        }
      }

      .preview-mobile-leaderboard {
        max-width: 420px;

        .image-wrapper {
          display: inline-flex;
          margin-right: 16px;

          .image-slot {
            img {
              max-height: 48px;
            }
          }

          .image-slot + .image-slot {
            margin-left: 16px;
          }
        }

        .info-wrapper {
          display: inline-flex;
          align-items: center;

          .product-name {
            -webkit-line-clamp: 1;
          }

          .product-image {
            img {
              max-height: 48px;
            }
          }

          .headline {
            -webkit-line-clamp: 1;
          }

          .product-name + .product-image,
          .headline + .product-image {
            margin-left: 16px;
          }
        }
      }

      .preview-wide {
        max-width: 160px;
        min-height: 480px;

        .image-wrapper {
          display: inline-flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;

          img {
            max-width: 100%;
          }

          .image-slot + .image-slot {
            margin-top: 8px;
          }
        }

        .info-wrapper {
          text-align: center;

          .product-name {
            -webkit-line-clamp: 3;
          }

          .btn-shop {
            width: 100%;
          }

          .product-name + .btn-shop {
            margin-top: 8px;
          }
        }
      }

      .preview-med-rect {
        display: inline-block;
        max-width: 320px;

        .image-wrapper {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          .image-slot {
            flex: 1;
          }

          img {
            max-width: 100%;
          }

          .headline {
            -webkit-line-clamp: 3;
          }

          img + .headline {
            margin-top: 4px;
          }

          .image-slot + .image-slot {
            margin-left: 8px;
          }
        }

        .info-wrapper {
          margin-top: 8px;

          .headline {
            -webkit-line-clamp: 3;
          }
        }
      }

      .preview-leaderboard {
        .image-wrapper {
          display: inline-flex;
          margin-right: 16px;

          .headline {
            -webkit-line-clamp: 3;
            text-align: left;
          }

          img {
            max-height: 48px;
          }

          .image-slot + .image-slot {
            margin-left: 16px;
          }
        }

        .info-wrapper {
          display: inline-flex;
          align-items: center;

          .product-name {
            -webkit-line-clamp: 1;
          }

          .product-image {
            img {
              max-height: 48px;
            }
          }

          .product-name + .product-image {
            margin-left: 16px;
          }
        }
      }

      .preview-billboard {
        .image-wrapper {
          display: inline-flex;
          align-items: center;
          margin-right: 16px;

          img {
            max-width: 160px;
          }

          .headline {
            -webkit-line-clamp: 3;
            text-align: left;
          }

          img + .headline {
            margin-top: 4px;
          }

          .image-slot + .image-slot {
            margin-left: 16px;
          }
        }

        .info-wrapper {
          max-width: 420px;

          .product-image {
            img {
              max-width: 160px;
            }
          }

          .headline {
            -webkit-line-clamp: 1;
          }

          .headline + .product-name,
          .product-image + .product-name {
            margin-top: 4px;
            -webkit-line-clamp: 1;
          }
        }
      }

      .preview-large-rect {
        max-width: 240px;
        min-height: 420px;

        .image-wrapper {
          display: inline-flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;

          .image-slot {
            display: inline-flex;
            align-items: center;

            &.logo-headline-slot {
              img {
                max-width: 50%;
              }

              .headline {
                margin-left: 4px;
                text-align: left;
              }
            }

            img {
              max-width: 100%;
            }

            .headline {
              -webkit-line-clamp: 3;
            }
          }

          .image-slot + .image-slot {
            margin-top: 16px;
          }
        }

        .info-wrapper {
          text-align: center;

          .product-name {
            -webkit-line-clamp: 2;
          }

          .btn-shop {
            width: 100%;
          }

          .product-name + .btn-shop {
            margin-top: 8px;
          }
        }
      }

      .preview-stripe {
        .image-wrapper {
          margin-right: 16px;

          img {
            max-height: 48px;
          }
        }

        .info-wrapper {
          display: inline-flex;
          align-items: center;

          .product-name,
          .headline {
            -webkit-line-clamp: 1;
          }

          img {
            max-height: 48px;
          }

          .btn-shop {
            min-width: 96px;
          }

          .product-headline + .product-image,
          .product-name + .product-image,
          .product-image + .product-image,
          .headline + .product-image,
          .product-image + .btn-shop,
          .product-name + .btn-shop,
          .product-headline + .btn-shop {
            margin-left: 16px;
          }
        }
      }

      .preview-video-contents {
        width: 320px;
        margin-top: 8px;
        border: 1px solid $--light-gray;
        overflow: hidden;

        .headline,
        .product-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-break: break-all;
          overflow: hidden;
        }

        .headline {
          font-weight: 700;
        }

        .info-wrapper {
          .shop-link {
            min-width: 90px;
            color: $--c6-blue;
            text-align: right;
          }
        }

        &.preview-page-video-mobile,
        &.preview-page-video-rect {
          .info-wrapper {
            display: flex;
            align-items: flex-start;

            .product-image + .product-name,
            .product-image + .headline {
              margin-left: 16px;
            }
          }
        }

        &.preview-page-video-mobile {
          .headline,
          .product-name {
            -webkit-line-clamp: 1;
          }

          .info-wrapper {
            padding: 4px;

            img {
              max-width: 120px;
            }
          }
        }

        &.preview-page-video-rect {
          .headline,
          .product-name {
            -webkit-line-clamp: 2;
          }

          .info-wrapper {
            padding: 8px;

            img {
              max-width: 120px;
            }
          }
        }

        &.preview-page-video-billboard {
          display: flex;
          align-items: center;
          width: auto;

          .headline,
          .product-name {
            -webkit-line-clamp: 2;
          }

          .headline {
            min-width: 96px;
          }

          .info-wrapper {
            padding: 8px;

            &.info-horizontal {
              display: inline-flex;
              align-items: center;

              .product-image + .product-name {
                margin-top: 0;
                margin-left: 8px;
              }
            }

            img {
              max-width: 120px;
            }

            .product-image + .product-name,
            .product-image + .headline {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .product-thumbnail-list {
      display: flex;
      align-items: center;

      .product-thumbnail-wrapper {
        padding: 16px;
        border: 1px solid $--light-gray;

        img {
          max-width: 72px;
        }
      }

      .product-thumbnail-wrapper + .product-thumbnail-wrapper {
        margin-left: 16px;
      }

      > .btn {
        margin-left: 16px;
      }
    }

    .sub-page-list {
      margin-top: 16px;
      margin-bottom: 8px;
      padding: 16px;
      border: 1px solid $--light-gray;

      .sub-page-wrapper {
        .sub-page-name {
          margin-bottom: 8px;
          padding: 4px;
          border-bottom: 1px solid $--light-gray;
        }

        .sub-page-details {
          display: flex;
          column-gap: 16px;

          > img {
            max-width: 120px;
          }

          .display-name-wrapper {
            > textarea {
              margin-top: 8px;
              margin-bottom: 8px;
            }
          }
        }
      }

      .sub-page-wrapper + .sub-page-wrapper {
        margin-top: 16px;
      }
    }
  }
}

.crop-field-wrapper {
  margin: 0 auto;
  width: 400px;

  .ReactCrop__image {
    width: 400px;
  }
}
