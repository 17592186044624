.table-tag {
  @include group-table-format;
  box-shadow: $card-box-shadow;

  .table-filter-shower {
    margin-left: 16px;
    margin-right: 16px;
  }

  .child-table-wrapper {
    padding-left: 64px + 160px;
  }

  .table-col {
    &.col-tag {
      flex: 0 0 120px;
    }

    &.col-campaign {
      flex: 0 0 180px;

      .contents {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
