.campaign-creative-section {
  position: relative;
  padding: 18px 24px;

  .creative-action-bar {
    margin-bottom: 4px;
  }

  .campaign-creative-panel {
    display: flex;
    justify-content: center;
    min-height: 450px;
    background-color: #363636;
    padding: 16px;

    &.desktop {
      align-items: center;
    }

    .preview-iframe {
      border: none;
    }

    .ad-creative-content {
      background-color: #fafafa;

      &.amazon-product-page-right {
        .creative {
          width: 250px;
          height: 250px;

          .product-image {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: 100%;
            height: 180px;

            &>img {
              width: 150px;
            }
          }

          .product-title {
            padding-left: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      &.amazon-product-page-middle {
        width: 700px;
        height: 150px;
        background-color: #fafafa;

        .creative {
          display: flex;
          padding: 10px;

          .product-image {
            margin-left: 10px;

            &>img {
              width: 120px;
            }
          }

          .product-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;

            .product-title {
              padding: 10px;
              font-weight: 600;
            }
          }
        }
      }

      &.amazon-mobile-rectangle {
        width: 500px;
        height: 150px;
        background-color: #fafafa;

        .creative {
          display: flex;
          padding: 10px;

          .product-image {
            margin-left: 10px;

            &>img {
              width: 120px;
            }
          }

          .product-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;

            .product-title {
              padding: 10px;
              font-weight: 600;
            }
          }
        }
      }

      &.mobile-leaderboard {
        width: 400px;
        height: 90px;
        background-color: #fafafa;

        .creative {
          display: flex;
          padding: 10px;

          .product-image {
            display: flex;
            align-items: center;
            margin-left: 10px;

            &>img {
              width: 50px;
            }
          }

          .product-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;

            .product-title {
              padding: 10px;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }

      &.wide-skyscraper {
        width: 150px;
        height: 400px;

        .creative {
          display: flex;
          flex-direction: column;
          padding: 10px;

          .product-image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 250px;

            &>img {
              width: 100px;
            }
          }

          .product-detail {
            .product-title {
              padding-left: 10px;
              margin-bottom: 10px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }

      &.medium-rectangle {
        width: 300px;
        height: 250px;

        .creative {
          .product-image {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            width: 100%;
            height: 180px;

            &>img {
              width: 150px;
            }
          }

          .product-title {
            padding-left: 10px;
            font-weight: 600;
          }
        }
      }

      &.leaderboard {
        width: 600px;
        height: 75px;

        .creative {
          display: flex;
          justify-content: space-between;
          padding: 7px 10px;

          .product-image {
            flex: 3;
            text-align: center;

            &>img {
              width: 60px;
            }
          }

          .product-detail {
            flex: 4;

            .product-title {
              padding-left: 10px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }

      &.billboard {

        .creative {
          display: flex;
          align-items: center;
          padding: 10px;
          width: 600px;
          height: 150px;

          .product-image {
            flex: 1;
            text-align: center;

            &>img {
              width: 75px;
            }
          }

          .product-detail {
            flex: 3;

            .product-title {
              padding-left: 10px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }

      &.large-rectangle {
        width: 250px;
        height: 400px;

        .creative {
          display: flex;
          flex-direction: column;
          padding: 10px;

          .product-image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 300px;

            &>img {
              width: 200px;
            }
          }

          .product-detail {
            .product-title {
              padding-left: 10px;
              margin-bottom: 10px;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }

      &.amazon-top-stripe {
        width: 600px;
        height: 50px;

        .creative {
          display: flex;
          justify-content: flex-start;
          padding: 5px;

          .product-image {
            flex: 1;
            text-align: center;

            &>img {
              width: 40px;
            }
          }

          .product-detail {
            flex: 5;

            .product-title {
              padding-left: 10px;
              font-size: 10px;
              font-weight: 600;
            }
          }

          .rs-rate {
            font-size: 10px;
          }

        }
      }

      .product-rate {
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;

        .rate-star {
          & > .rs-rate > li {
            margin: 0;
          }
        }

        .rate-number {
          padding: 5px 0 0 5px;
          font-size: 10px;
        }
      }
    }
  }
}

.sb-preview-container {
  .preview-contents {
    margin-top: 8px;
    padding: 8px 16px;
    border: 1px solid $--light-gray;

    &.preview-contents-for-video {
      .image-stripe {
        .product-item {
          display: flex;
          column-gap: 8px;
          align-items: center;

          .product-name {
            min-width: 120px;
          }
        }
      }
    }

    .headline {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      overflow: hidden;
    }

    .headline + .image-stripe,
    .image-stripe + .headline,
    .spotlight-top-section + .image-stripe {
      margin-top: 8px;
    }

    .image-stripe {
      display: flex;
      column-gap: 16px;
      overflow: auto;

      .brand-item {
        img {
          max-height: 120px;
        }

        .brand-name {
          font-size: 12px;
        }

        img + .brand-name {
          margin-top: 4px;
        }
      }

      .product-item {
        .product-image {
          max-height: 120px;
        }

        .product-name {
          display: -webkit-box;
          font-size: 12px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
          overflow: hidden;
        }

        .product-image + .product-name {
          margin-top: 4px;
        }

        .prime-image {
          max-width: 48px;
        }

        .product-name + .prime-image {
          margin-top: 4px;
        }
      }
    }

    .brand-headline-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      img {
        max-height: 60px;
      }

      img + .headline {
        margin-top: 16px;
      }

      .headline {
        font-size: 16px;
      }

      .brand-name {
        margin-top: 16px;
        font-size: 12px;
        color: $--c6-blue;
      }
    }

    .mobile-detail-section {
      width: 240px;
      padding: 16px;
      border: 1px solid $--light-gray;

      .shop-link-item {
        margin-top: 16px;
        padding-top: 8px;
        border-top: 1px solid $--light-gray;
        font-size: 12px;
        color: $--c6-blue;
      }
    }

    .spotlight-top-section {
      display: flex;
      column-gap: 16px;
      align-items: center;

      img {
        max-height: 64px;
      }
    }

    .spotlight-bottom-section {
      border-top: 1px solid $--light-gray;
      margin-top: 16px;
      padding-top: 8px;
      font-size: 12px;
      color: $--c6-blue;
    }
  }

  .preview-video-contents {
    margin-top: 8px;
    border: 1px solid $--light-gray;
    overflow: hidden;

    &.vertical-stack {
      width: 320px;
    }

    &.horizontal-stack {
      display: flex;
      padding: 8px 16px;
      column-gap: 16px;
      align-items: center;
    }

    .product-widget {
      display: flex;

      .product-image {
        flex: 4;

        img {
          width: 100%;
        }
      }

      .product-details {
        flex: 6;
        padding: 8px;

        .sponsor-label {
          font-size: 12px;
        }

        .product-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-top: 8px;
          word-break: break-all;
          overflow: hidden;
        }

        .product-price {
          margin-top: 8px;
        }

        .prime-image {
          max-width: 48px;
          margin-top: 8px;
        }
      }
    }
  }
}
