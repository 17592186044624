.template-editor-component {
  .pane-header {
    .input-wrapper {
      position: relative;
      margin-left: 16px;

      input {
        padding-right: 24px;
        border: none;
        border-bottom: 1px dashed $--gray;
        font-size: 18px;
        color: $--c6-black;
      }

      > svg {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -7px;
      }
    }
  }

  .section-tab-selector {
    margin-top: 16px;
  }
}