.auth-layout {
  display: flex;
  min-height: 100vh;

  .auth-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &.loading {
      opacity: 0.5;
    }

    .logo-header {
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: 700;
      color: #000;
      text-align: center;

      img {
        margin-right: 16px;
        width: 38px;
      }
    }

    .auth-form {
      min-width: 320px;

      .form-title {
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 700;
        color: $--c6-black;
      }

      .form-sub-title {
        margin-bottom: 16px;
        font-size: 16px;
        color: $--c6-gray;
      }

      .name-input-container {
        margin-bottom: 16px;

        input {
          @include input-format;
        }

        input + input {
          margin-top: 8px;
        }
      }

      .type-container {
        margin-bottom: 16px;

        .section-title {
          margin-bottom: 16px;
          font-size: 18px;
          font-weight: 700;
          color: $--c6-black;
        }

        .type-wrapper {
          padding: 16px;
          border: 1px solid $--light-gray;
          border-radius: 8px;
          cursor: pointer;

          &.active {
            background-color: #EDEFFF;
            border-color: #5B22E5;
          }

          .radio-wrapper {
            display: flex;
            align-items: center;
            font-weight: 700;

            input {
              margin-right: 8px;
            }
          }

          .type-note {
            margin-top: 4px;
            font-size: 12px;
            color: $--soft-gray;
          }
        }

        .type-wrapper + .type-wrapper {
          margin-top: 8px;
        }
      }
    }
  }
}

.auth-side-component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-image: url('../../assets/svg/login/c6-background.png');
  background-size: cover;
  color: #fff;
  text-align: center;

  .c6-family-notice {
    margin-top: 32px;
    font-size: 28px;
    font-weight: 700;
  }

  .page-notice {
    margin-top: 16px;
    padding: 0 64px;
    font-size: 18px;
    font-weight: 700;
  }

  .footer-notice {
    position: absolute;
    left: 32px;
    bottom: 32px;
    color: $--c6-purple;
  }
}

@media (max-width: 768px) {
  .auth-layout {
    flex-direction: column;

    .auth-page,
    .auth-side-component {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}
