.product-chart-component {
  display: flex;
  height: 375px;
  margin-top: 16px;
  padding: 18px 24px;
  background: $--c6-white;

  .recharts-xAxis {
    .recharts-cartesian-axis-line {
      stroke: none !important;
    }
    .recharts-cartesian-axis-tick-line {
      stroke: none !important;
    }
  }

  .recharts-yAxis {
    .recharts-cartesian-axis-line {
      stroke: none !important;
    }
    .recharts-cartesian-axis-tick-line {
      stroke: none !important;
    }
  }

  .recharts-legend-wrapper {
    .legend-list {
      margin-top: 10px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      li {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        .bullet {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-right: 4px;
          border: 2px transparent solid;
          border-radius: 6px;
        }

        button {
          background-color: transparent;
          font-size: 15px;
        }

        button + svg {
          margin-left: 4px;
        }

        &.add-action {
          svg {
            width: 24px;
            height: 24px;
            padding: 4px;
            border-radius: 2px;
            background-color: $--c6-blue;
            color: #fff;
          }
        }

        &.metric-selector-container {
          min-width: 150px;
          .metric-selector {
            width: 100%;
          }
        }
      }

      li + li {
        margin-left: 12px;
      }
    }
  }
}