.table-filter-shower {
  margin: 8px 0;

  .filter-title {
    font-weight: 700;
  }

  .filter-title + .filter-contents {
    margin-left: 16px;
  }

  .filter-contents {
    > span {
      display: inline-flex;
      padding: 8px;
      align-items: center;
      background-color: $--c6-white;
      border: 1px solid $--light-gray;
      border-radius: 4px;

      .close-icon {
        margin-left: 4px;
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        color: $--gray;
        text-align: center;
        cursor: pointer;
      }
    }

    > span + span {
      margin-left: 8px;
    }
  }
}
