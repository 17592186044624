.dsp-component {
  display: flex;
  padding: 10px 16px;

  .dsp-nav-panel {
    min-width: 200px;

    .dsp-advertiser-header {
      margin: 8px 0;

      .advertiser-info {
        display: flex;
        width: 200px;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        border: 1px solid $--light-gray;
        border-radius: 4px;
        cursor: pointer;

        .close-icon {
          height: 24px;
          font-size: 24px;
          line-height: 24px;
          color: $--gray;

          &:hover {
            color: $--dark-gray;
          }
        }
      }
    }

    .dsp-account-selector {
      .dropdown {
        position: relative;
        display: inline-block;

        &:focus-within {
          .dropdown-content {
            display: block;
          }
        }

        &.account-list {
          .dropdown-content {
            left: 0;

            a {
              justify-content: start;
            }
          }
        }

        .dropdown-toggle {
          display: flex;
          align-items: center;
          padding: 16px;
          background-color: transparent;
          cursor: pointer;

          svg {
            margin-left: 8px;
          }
        }

        .dropdown-content {
          position: absolute;
          display: none;
          min-width: 275px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          background-color: white;
          // z-index of new campaign button is 5.
          z-index: 6;

          a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 16px;
            color: #000;
            text-decoration: none;

            &:hover {
              background-color: #f1f1f1;
            }
          }

          .account-filter {
            padding: 10px 16px 0 16px;

            .filter-input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 2px solid #aaa;

              &:focus {
                border-bottom: 2px solid #777;
              }
            }
          }
        }
      }
    }
  }

  .dsp-content {
    width: 100%;
    margin-left: 16px;

    .dsp-table {
      .dsp-table-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .dsp-table-search-box {
          width: 200px;
        }

        .btn-export-csv {
          margin-right: 8px;
        }
      }

      .table-header-cell {
        .rs-table-cell {
          background: #f9fafb;
          font-size: 11px;
          color: #292929;
          font-weight: 700;
        }
      }

      .col-advertiser {
        color: #1675e0;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.dsp-column-setting-modal {
  .setting-content {
    display: flex;
    justify-content: space-between;

    span.box-label {
      position: absolute;
      top: -10px;
      left: 24px;
      background: #fff;
      padding: 0 8px;
    }

    .setting-option {
      width: 48%;

      .setting-column-list {
        position: relative;
        height: 500px;
        border: 1px solid $--light-gray;
        border-radius: 4px;
        padding: 16px;

        .campaign-columns {
          height: 430px;
        }
      }

      .setting-preset {
        position: relative;
        margin-top: 24px;
        border: 1px solid $--light-gray;
        border-radius: 4px;
        padding: 16px;

        .preset-setting-select {
          margin-top: 8px;
        }
      }
    }

    .setting-selected {
      position: relative;
      width: 48%;
      border: 1px solid $--light-gray;
      border-radius: 4px;
      padding: 16px;

      .selected-column-list {
        margin-top: 16px;
        height: 550px;
      }
    }
  }
}