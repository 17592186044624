.rule-table-component {
  box-shadow: $card-box-shadow;

  .toggle-wrapper {
    display: flex;
    align-items: center;
    padding: 16px 0 16px 24px;

    .rs-btn-toggle {
      min-width: 130px;
    }

    .rule-hierarchy {
      margin-left: 16px;

      strong {
        display: block;
      }

      .release-note {
        font-size: 12px;
        color: $--gray;
      }
    }
  }

  .table-rules {
    @include group-table-format;
    @include compact-header-table-format;

    .child-table-wrapper {
      padding-left: 64px + 160px - 28px;
    }

    .table-col {
      &.col-rule {
        flex: 0 0 160px;
      }

      &.col-campaigns {
        svg {
          margin-left: 4px;
        }
      }

      &.col-action {
        justify-content: end;
        padding-right: 28px!important;

        .create-template-link {
          stroke: $--c6-blue;
          cursor: pointer;
        }

        .remove-from-template-link {
          stroke: $--red;
          cursor: pointer;
        }
      }
    }
  }

  .table-campaign-rules {
    @include group-table-format;
    @include compact-header-table-format;
    @include campaign-name-cell;

    .child-table-wrapper {
      padding-left: 64px + 240px - 28px;
    }

    .table-header-right {
      column-gap: 8px;
    }

    .table-body {
      .table-col {
        &.col-campaign {
          flex-direction: column;
          align-items: flex-start!important;
          justify-content: center;
        }
      }

      .content-header {
        .table-col.col-campaign {
          flex-direction: row;
          align-items: center!important;
          justify-content: flex-start;
        }
      }
    }

    .table-col {
      flex: 1;

      &.col-campaign {
        flex: 0 0 240px;

        .campaign-name {
          width: 100%;
          margin-bottom: 4px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .campaign-status {
          margin-bottom: 4px;
          font-size: 10px;

          &.on {
            color: #2EB73C;
          }

          &.off {
            color: $--accent;
          }
        }
      }

      &.col-stickers {
        .rule-sticker {
          display: inline-block;
          padding: 2px;

          > span {
            display: inline-block;
            padding: 4px 6px;
            background-color: rgba(22, 117, 224, 0.1);
            border: 1px solid rgba(22, 117, 224, 0.5);
            border-radius: 4px;
            font-size: 12px;
            color: #1675E0;
          }
        }
      }

      &.col-rule {
        .rs-btn-toggle {
          margin-right: 8px;
          background-color: #ddadaa;

          &.rs-btn-toggle-checked {
            background-color: #32b83f;
          }
        }

        .rule-name {
          display: flex;
          align-items: center;
          cursor: pointer;

          .rule-name-contents {
            display: flex;
            flex-direction: column;

            .rule-sp-details {
              font-size: 10px;
              color: $--gray;
            }
          }

          > svg {
            margin-left: 8px;
          }
        }
      }

      &.col-template {
        ul {
          margin-bottom: 0;
        }

        a,
        a + span {
          margin-left: 8px;
        }

        .action-remove {
          color: $--red;
        }
      }
    }
  }
}
