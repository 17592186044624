.tutorial-link {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #575757;
  font-size: 14px;

  svg {
    margin-right: 8px;
  }
}
