.account-health-page {
  @include page-layout;

  .page-header {
    .button-container {
      .btn.disabled {
        pointer-events: none;
      }

      .btn + .btn {
        margin-left: 16px;
      }
    }

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;

      .button-container {
        width: 100%;

        .btn {
          display: block;
          width: 100%;
        }

        .btn + .btn {
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }

  .report-content {
    padding-right: 240px;
  }
}

.health-report-customizer {
  .rs-modal-body {
    display: flex;
  }

  .tab-list {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    border-right: 2px solid $--light-gray;

    & > span {
      padding: 4px 4px 4px 8px;
      border-left: 2px solid $--c6-white;
      cursor: pointer;

      &.selected {
        border-left-color: $--c6-blue;
        background: $--green;
      }
    }
  }

  .metric-list {
    margin-left: 16px;

    .checkbox-component {
      margin-top: 12px;
    }
  }
}

.save-template-modal {
  input {
    @include input-format;
  }
}

.template-picker-modal {
  .rs-modal-body {
    height: 240px;
  }

  .template-option {
    display: flex!important;
    align-items: center;
    justify-content: space-between;

    &.selected {
      a {
        color: $--green;
      }
    }

    a {
      font-size: 12px;
    }
  }
}
