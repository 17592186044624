.campaign-detail-bid-op {
  .table-keywords,
  .table-targets {
    @include sortable-table-format;

    .table-header-right {
      .select-wrapper {
        display: flex;
        align-items: center;
        margin: 0 8px;

        > span {
          margin-right: 8px;
          font-weight: 700;
        }

        .match-type-selector__menu {
          // z-index of date picker is 5.
          z-index: 6;
        }
      }
    }

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 80px;
      }

      &.col-keyword,
      &.col-target {
        flex: 0 0 150px;
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .target-text-wrapper {
          display: flex;
          align-items: center;

          > strong {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          > a {
            margin-left: 4px;
          }
        }

        .meta-data {
          margin-top: 4px;
          font-size: 11px;
          color: $--dark-gray;
        }
      }

      &.col-keyword {
        > strong {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-target {
        flex: 0 0 160px;
      }

      &.col-genius-bid {
        color: $--c6-pink;

        > svg {
          margin-left: 4px;
          color: $--yellow;
        }
      }
    }
  }
}
