.table-budget-recommendation {
  @include sortable-table-format;
  box-shadow: $card-box-shadow;

  > .table-header {
    .table-header-right {
      column-gap: 8px;

      > .rs-dropdown {
        .rs-dropdown-item-content {
          display: flex;
          align-items: center;

          > svg {
            margin-left: 8px;
          }
        }
      }

      > svg {
        margin-left: 8px;
      }
    }
  }

  .table-col {
    flex: 1;

    &:first-child {
      padding: 0 15px !important;
    }

    &:not(.col-check) {
      min-width: 80px;
    }

    &.col-campaign {
      min-width: 200px;
    }

    &.col-percent-time {
      min-width: 200px;

      .rs-progress-line {
        width: 80%;
        padding: 0 0;

        .rs-progress-info {
          padding-left: 5px;

          .rs-progress-info-status {
            font-size: 12px;
            line-height: inherit;
          }
        }
      }
    }

    &.col-bidding-strategy {
      min-width: 150px;
    }

    &.missed-opportunity-col {
      min-width: 150px;

      & > span {
        padding: 0 9px;
        line-height: 1.5;
        border-radius: 10px;

        &.impressions {
          background: #8ff1e0;
          color: #238976;
        }

        &.sales {
          background: #ffcfcf;
          color: #db3e1a;
        }

        &.clicks {
          background: #f1ef8f;
          color: #a56e08;
        }
      }
    }
  }

  .table-row:not(.content-header) {
    @include campaign-name-cell;

    .col-campaign {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .contents {
        width: 100%;
      }
    }
  }
}

.daily-budget-modal {
  .action-title {
    margin-left: 8px;
  }

  .percent-control-btn-group {
    margin: 0 8px;

    .percent-control-btn {
      border: 1px solid $--light-gray;

      &.active {
        background: $--light-gray;
      }
    }
  }
}
