.table-portfolio {
  @include group-table-format;
  box-shadow: $card-box-shadow;

  .table-filter-shower {
    margin-left: 16px;
    margin-right: 16px;
  }

  .child-table-wrapper {
    padding-left: 64px + 160px;
  }

  > .table-body {
    > .table-row {
      .col-action {
        width: 0px;
        min-width: auto;
      }

      .col-campaign {
        flex: 0 0 96px;
        min-width: auto;
      }
    }
  }

  .table-col {
    &.col-portfolio {
      flex: 0 0 110px;
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: center;
      row-gap: 4px;
    }

    &.col-budget {
      flex: 0 0 120px;

      .budget-setting-contents {
        font-size: 11px;
        color: $--dark-gray;
      }
    }

    &.col-campaign {
      flex: 0 0 180px;

      .contents {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.portfolio-budget-modal {
  .contents-area {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .portfolio-list {
    .portfolio-wrapper {
      display: inline-flex;
      align-items: center;
      margin: 4px;
      padding: 4px 8px;
      background-color: #f8f8f8;
      border-radius: 4px;
      font-size: 12px;
    }
  }

  .policy-wrapper,
  .amount-wrapper,
  .date-wrapper,
  .timezone-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;

    label {
      min-width: 80px;
    }
  }

  .policy-wrapper {
    .policy-selector__menu {
      // z-index of date picker is 5.
      z-index: 6;
    }
  }

  .amount-wrapper {
    > input {
      @include input-format();
    }
  }

  .timezone-wrapper {
    .timezone-selector {
      min-width: 180px;
    }
  }
}
