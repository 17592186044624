.header-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 32px;

  .header-left {
    svg {
      cursor: pointer;

      &:hover {
        stroke: $--c6-black;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .coin-component {
      position: relative;

      a {
        display: flex;
        align-items: center;
        padding: 2px 4px;
        border: 1px solid $--light-gray;
        border-radius: 4px;
        color: $--c6-black;
        text-decoration: none;

        .coin-label {
          margin-right: 8px;
        }
      }

      .coin-congrats-wrapper {
        position: absolute;
        display: flex;
        top: 100%;
        left: 50%;
        width: 200px;
        align-items: center;
        margin-top: 8px;
        margin-left: -100px;
        padding: 8px 16px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 3px 18px rgba(186, 186, 186, 0.44);

        .coin-label-wrapper {
          width: 40px;
          height: 40px;
          margin-right: 16px;
          padding: 5px;
          background-image: url('../../assets/svg/coin-congrats.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          .coin-label {
            display: block;
            width: 30px;
            height: 30px;
          }
        }

        .coin-info {
          .coin-amount {
            font-size: 15px;
            font-weight: 600;
          }

          .coin-description {
            font-size: 12px;
            color: #777;
          }
        }
      }
    }

    > svg {
      cursor: pointer;
    }

    .dropdown {
      position: relative;
      display: inline-block;

      &:focus-within {
        .dropdown-content {
          display: block;
        }
      }

      &.currency {
        .dropdown-content {
          min-width: 256px;
        }
      }

      &.account-list {
        margin-right: -16px;
        cursor: pointer;

        &:hover {
          svg {
            path {
              fill: $--gray;
            }
          }
        }

        // Need to be same as the one on settings page (except size, font-size, and margins).
        .initials-display {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 8px;
          width: 32px;
          height: 32px;
          background-color: #fbab34;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }

        .dropdown-content {
          right: 0;

          a {
            justify-content: start;
          }
        }
      }

      .dropdown-toggle {
        display: flex;
        align-items: center;
        padding: 8px;
        color: $--c6-black;
        cursor: pointer;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        .value-text {
          margin-right: 6px;
        }
      }

      .dropdown-content {
        position: absolute;
        display: none;
        min-width: 275px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: white;
        // z-index of new campaign button is 5.
        z-index: 6;

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 16px;
          color: #000;
          text-decoration: none;

          &:hover {
            background-color: #f1f1f1;
          }

          &.account-toggle {
            border-top: 1px solid $--light-gray;
            font-size: 12px;
            font-style: italic;
            color: $--gray;
          }

          .currency-text {
            flex: 1;
          }
        }

        .sort-options {
          display: flex;
          align-items: center;

          .sort-option-panel {
            padding: 12px 0px 12px 16px;

            .account-country-selector {
              width: 160px;
            }
          }
        }

        .account-filter {
          padding: 10px 16px 0 16px;

          .filter-input {
            width: 100%;
            padding: 5px;
            border: none;
            border-bottom: 2px solid #aaa;

            &:focus {
              border-bottom: 2px solid #777;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    margin: 20px 10px 10px;

    .header-right {
      .currency {
        display: none;
      }
    }
  }
}
