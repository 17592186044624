.weekparting-manager-component,
.weekparting-template-editor-component {
  @include rule-manager(840px);

  .pane-body {
    .rule-section {
      .section-header {
        margin-bottom: 4px;
      }

      .dow-section {
        background-color: #F8F9F9;

        .dow-section-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
          cursor: pointer;

          &.expanded {
            .dow-name {
              > svg {
                transform: rotate(-90deg);

                path {
                  fill: #1675E0;
                }
              }
            }
          }

          .dow-name {
            display: flex;
            align-items: center;
            font-weight: 700;

            > svg {
              margin-right: 8px;
            }

            .rule-indicator {
              margin-left: 8px;
              font-size: 12px;
              font-weight: normal;
            }
          }

          > a {
            font-size: 12px;
            color: #EA305C;
          }
        }

        .dow-section-body {
          padding: 0 32px;

          .section-note {
            font-size: 12px;
            color: $--gray;
          }

          .rule-sub-section {
            padding: 16px 0;
            box-shadow: inset 0px -1px 0px #DDDDDD;

            &:first-child {
              padding-top: 8px;
            }

            &:last-child {
              box-shadow: none;
            }

            .sub-section-name {
              margin-bottom: 8px;
              font-weight: 700;
              color: $--c6-black;
            }

            .sub-section-body {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .rule-settings-container {
                display: flex;
                align-items: center;

                .value-input {
                  margin-left: 8px;
                  max-width: 160px;
                }
              }

              .clear-link {
                font-size: 12px;
                color: #EA305C;
                cursor: pointer;
              }
            }

            .sub-section-error {
              margin-top: 4px;
              color: $--red;
            }
          }

          .rule-select__menu {
            // This should be greater than z-index of footer to be visible.
            z-index: 6;
          }
        }
      }

      .dow-section + .dow-section {
        border-top: 1px solid $--light-gray;
      }
    }
  }
}

.weekparting-template-selector-modal {
  @include rule-template-selector;

  .template-details {
    .dow-name {
      font-weight: 700;
    }

    .slot-rules {
      padding-top: 8px;
      border-top: 1px solid $--light-gray;
    }
  }
}
