@mixin right-pane() {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff;
  padding: 20px 24px;
  // z-index of date range picker popup is 8,
  // so a value less than 8 must be used
  // to let date range picker work inside right pane.
  z-index: 7;

  .pane-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pane-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: $--c6-black;
      margin-right: 8px;

      svg {
        margin-left: 8px;
      }
    }

    .close-icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      line-height: 24px;
      color: $--gray;
      cursor: pointer;

      &:hover {
        color: $--c6-black;
      }
    }
  }
}