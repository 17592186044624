$--sp-manager-width: 720px;

.apm-component,
.template-editor-component {
  @include right-pane();
  width: $--sp-manager-width;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;

  .loader-component.sp-loader {
    position: fixed;
    left: auto;
    right: $--sp-manager-width / 2 - 32px;
  }

  .pane-header {
    .left-column {
      display: flex;
      align-items: center;

      .rs-btn-toggle {
        margin-left: 16px;
        background-color: #ddadaa;

        &.rs-btn-toggle-checked {
          background-color: #32b83f;
        }
      }
    }

    .right-column {
      display: flex;
      align-items: center;
    }
  }

  .top-container {
    position: sticky;
    display: block;
    top: 0;
    margin: 0 -24px;
    padding: 20px 24px 8px;
    background-color: #fff;
    // z-index of react-select is 1.
    // z-index of date picker is 5.
    z-index: 6;
  }

  .campaign-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 8px 16px;
    border: 1px solid #c8c8c8;
    border-radius: 4px;

    .campaign-detail {
      flex: 1;

      .campaign-type {
        display: flex;
        margin-top: 4px;
        color: $--gray;
        font-size: 12px;

        span + span {
          margin-left: 12px;
        }
      }

      .campaign-template {
        display: flex;
        margin-top: 4px;
        font-size: 12px;

        a {
          text-decoration: none;
        }

        .tutorial-link {
          margin-left: 16px;
          font-size: 12px;

          svg {
            margin-right: 8px;
          }
        }
      }
    }

    .rule-stickers {
      flex: 1;
      text-align: right;

      .rule-sticker {
        display: inline-block;
        padding: 2px;

        > span {
          display: inline-block;
          padding: 4px 6px;
          background-color: rgba(22, 117, 224, 0.1);
          border: 1px solid rgba(22, 117, 224, 0.5);
          border-radius: 4px;
          font-size: 12px;
          color: #1675E0;
          cursor: pointer;
        }
      }
    }
  }

  .adgroup-selector {
    margin-top: 16px;
    margin-bottom: 16px;

    > label {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      svg {
        margin-left: 8px;
      }
    }
  }

  .section-tab-selector {
    display: flex;

    .section-tab {
      flex: 1;
      padding: 12px 24px;
      border: 1px solid #c7c7c7;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      cursor: pointer;

      &.selected,
      &:hover {
        background-color: rgba(248, 232, 176, 0.22);
        border: 1px solid rgba(251, 171, 52, 0.5);
      }
    }
  }

  .pane-body {
    .section-container {
      margin: 0 -24px;

      .subsection-container {
        padding: 16px 24px;
        border-bottom: 1px solid $--light-gray;

        &:last-child {
          border-bottom: none;
        }

        .subsection-header {
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          display: flex;
          align-items: center;

          a {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 400;
          }

          svg {
            margin-left: 8px;
            vertical-align: middle;
          }
        }

        .subsection-body {
          padding-top: 16px;

          &.read-only {
            opacity: .85;
            pointer-events: none;
          }

          .tab-list {
            display: flex;
            margin-bottom: 16px;

            .tab {
              display: inline-flex;
              flex: 1;
              align-items: center;
              margin-right: 16px;
              padding-bottom: 4px;
              background-color: transparent;
              border-bottom: 2px solid white;
              font-size: 16px;
              font-weight: 600;
              color: $--gray;

              &.selected,
              &:hover {
                color: $--c6-black;
                border-bottom-color: $--c6-black;
              }

              &:focus,
              &:hover {
                text-decoration: none;
              }

              .link-name {
                display: inline-flex;
                align-items: center;

                svg {
                  margin-left: 8px;
                }
              }
            }
          }
        }

        .tutorial-link {
          margin-left: auto !important;
          font-weight: 400;
          font-size: 14px;
        }

        .placement-warning {
          margin-bottom: 8px;
          color: $--red;
        }
      }

      .container-label {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;

        svg {
          margin-left: 8px;
        }
      }

      .op-keyword-contents {
        &.read-only {
          opacity: .85;
          pointer-events: none;
        }
      }

      .placement-op-container {
        &.disabled {
          opacity: .5;
          pointer-events: none;
        }
      }

      .keyword-basic-container {
        position: relative;

        &.disabled {
          opacity: .5;
          pointer-events: none;
        }

        .override-overlay {
          position: absolute;
          display: flex;
          align-items: center;
          left: 0;
          top: 20px;
          bottom: 0;
          width: 100%;
          padding: 0 16px;
          background-color: #fff;
          text-align: center;
          z-index: 1;
        }
      }

      .keyword-advanced-setting {
        padding: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;

        .setting-header {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .checkbox-label {
            font-weight: 600;
          }

          .clone-button {
            margin-left: auto;
            color: $--c6-black;
            text-decoration: none;

            svg {
              width: 18px;
              height: 18px;
              margin-right: 8px;
              vertical-align: bottom;
            }
          }
        }

        .acos-warning {
          margin-top: 4px;
          color: $--accent;
        }
      }

      .keyword-advanced-setting + .keyword-advanced-setting {
        margin-top: 8px;
      }

      .keyword-ignore-container {
        ul {
          padding-left: 24px;
        }

        textarea {
          width: 100%;
          height: 75px;
          padding: 10px 12px;
          border: 1px solid $--light-gray;
          border-radius: 6px;
          resize: none;
        }
      }

      .nta-section {
        padding: 16px 16px 0;
        border: 1px solid #ddd;
        border-radius: 4px;

        .setting-header {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .checkbox-info-wrapper {
            margin-bottom: 0;
          }

          .clone-button {
            margin-left: auto;
            color: $--c6-black;
            text-decoration: none;

            svg {
              width: 18px;
              height: 18px;
              margin-right: 8px;
              vertical-align: bottom;
            }
          }
        }
      }

      .nta-section + .nta-section {
        margin-top: 16px;
      }

      .frequency-list {
        display: flex;

        a {
          flex: 1;
          padding: 8px 0;
          border: 1px solid $--light-gray;
          color: $--c6-black;
          text-align: center;
          text-decoration: none;

          &.active {
            background: $--c6-blue;
            border-color: $--c6-blue;
            color: #fff;
          }

          &:first-child {
            border-radius: 6px 0px 0px 6px;
          }

          &:last-child {
            border-radius: 0 6px 6px 0;
          }
        }

        a + a {
          border-left: none;
        }
      }

      .field-row {
        display: flex;
        margin-left: -4px;
        margin-right: -4px;

        &.disabled {
          opacity: .5;
          pointer-events: none;
        }

        .field-wrapper {
          flex: 1;
          margin: 0 4px;
          min-width: 0;

          .field-name {
            display: flex;
            align-items: center;
				    margin-bottom: 4px;

            strong {
              font-weight: 600;
            }

            svg {
              margin-left: 8px;
            }
          }

          > input {
            @include input-format;

            &.shrinked-input {
              max-width: 90px;
            }
          }

          input + .input-desc,
          .input-desc + input {
            margin-left: 8px;
          }

          .lookback-selector__menu,
          .action-selector__menu {
            // This should be greater than z-index of footer to be visible.
            z-index: 6;
          }

          &.no-label-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 24px;

            .select-wrapper {
              flex: 1;
            }

            .select-wrapper + svg {
              margin-left: 8px;
            }
          }

          &.inline-wrapper {
            display: flex;
            align-items: center;

            .field-name {
              margin-bottom: 0;
              margin-right: 16px;
            }

            > input {
              width: auto;
            }
          }
        }
      }

      .checkbox-component + svg {
        margin-left: 8px;
      }

      .lookback-wrapper {
        margin-top: 16px;
        margin-bottom: 16px;

        .lookback-selector__menu {
          // This should be greater than z-index of footer to be visible.
          z-index: 6;
        }
      }

      .field-row + .field-row,
      .checkbox-component + .field-row,
      .field-row + .checkbox-component,
      .field-row + .checkbox-info-wrapper {
        margin-top: 16px;
      }

      .check-input-container {
        display: flex;
        align-items: center;

        input {
          @include input-format;
          width: auto;
          min-width: 0;
          margin-left: 8px;

          &.shrinked-input {
            max-width: 60px;
          }
        }
      }

      .testing-header {
        .testing-label {
          display: block;
          margin-bottom: 4px;
        }

        .testing-header-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .testing-day-selector,
          .btn {
            flex: 1;
          }

          .split-test-email-wrapper  {
            display: flex;
            flex: 2;
            align-items: center;
            margin: 0 16px;
          }

          .testing-details {
            display: flex;
            flex: 3;
            margin-right: 32px;

            .testing-details-field {
              flex: 1;
              padding: 4px 8px;
              border: 1px solid $--light-gray;

              &:first-child {
                border-radius: 4px 0 0 4px;
              }

              &:last-child {
                border-radius: 0 4px 4px 0;
              }

              .field-name {
                color: $--gray;
                font-size: 10px;
              }
            }

            .testing-details-field + .testing-details-field {
              border-left: none;
            }
          }
        }
      }

      .testing-metric-list {
        display: flex;
        margin: 16px -8px 0;

        .testing-metric {
          flex: 1;
          margin: 0 8px;
          border: 1px solid $--light-gray;
          border-radius: 4px;
          text-align: center;

          .metric-name {
            padding: 4px;
            border-bottom: 1px solid $--light-gray;
          }

          .metric-results {
            display: flex;

            .metric-result-split {
              flex: 1;
              padding: 4px;
              border-bottom: 1px solid $--light-gray;

              .metric-result-label {
                margin-bottom: 8px;
              }

              .metric-result-value {
                font-weight: 600;
              }
            }

            .metric-result-split + .metric-result-split {
              border-left: 1px solid $--light-gray;
            }
          }

          .metric-summary {
            padding: 4px;
            font-weight: 600;
          }
        }
      }

      .checkbox-info-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
      }

      .unit-selector {
        flex: 2 !important;

        .unit-selector-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > * {
            flex: 1;
          }

          > input {
            @include input-format;
            flex: 2;
            margin: 0 16px;
          }

          .unit-selector__menu {
            // This should be greater than z-index of footer to be visible.
            z-index: 6;
          }
        }
      }

      .step-wrapper {
        margin-top: 16px;

        &.disabled {
          opacity: .5;
          pointer-events: none;
        }

        .step-desc {
          margin-bottom: 16px;

          strong {
            font-weight: 600;
          }

          > svg {
            margin-left: 8px;
            vertical-align: middle;
          }
        }
      }

      .table-wrapper {
        margin-top: 16px;

        &.disabled {
          opacity: .5;
          pointer-events: none;
        }

        .table-wrapper-header {
          display: flex;
          align-items: center;

          &.disabled {
            opacity: .5;
            pointer-events: none;
          }

          strong {
            font-weight: 600;
          }

          strong + button,
          .checkbox-component  {
            margin-left: 16px
          }

          > svg {
            margin-left: 8px;
          }
        }

        .table-header-desc {
          margin-top: 4px;
          color: $--gray;
        }

        .table-wrapper-header + .table-wrapper-header,
        .table-wrapper-header + .smart-select-wrapper {
          margin-top: 16px;
        }
      }

      .table-adgroups {
        @include table-format;

        > .table-header {
          .rs-input-group-addon {
            z-index: 1;
          }

          .rs-input {
            z-index: auto;
          }
        }

        > .table-body {
          overflow: visible;
        }

        > .table-body .table-row {
          min-height: auto;
          height: 50px;

          .table-col {
            height: 50px;
          }
        }

        .table-pagination {
          @include pagination-compact;
        }

        .table-col {
          flex: 1;

          &.col-action {
            flex: 0 0 72px;

            .btn {
              width: 28px;
              padding: 4px 10px;
              border-radius: 50%;
            }

            .btn + .btn {
              margin-left: 4px;
            }
          }

          > input {
            @include input-format;
          }

          .match-type-selector__menu {
            // This should be greater than z-index of footer to be visible.
            z-index: 6;
          }
        }
      }

      .st-criteria-label {
        margin-top: 16px;
        margin-bottom: 16px;

        &.disabled {
          opacity: .5;
          pointer-events: none;
        }

        strong {
          font-weight: 600;
        }
      }

      .negative-product-targeting-section {
        margin-top: 16px;
        padding: 16px;
        border: 1px solid $--light-gray;
        border-radius: 4px;

        .setting-header {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .checkbox-label {
            font-weight: 600;
          }

          .clone-button {
            margin-left: auto;
            color: $--c6-black;
            text-decoration: none;

            svg {
              width: 18px;
              height: 18px;
              margin-right: 8px;
              vertical-align: bottom;
            }
          }
        }
      }
    }

    .table-campaigns-apply {
      @include table-format;

      .table-header-right {
        .ad-type-selector {
          min-width: 180px;
        }
      }

      .table-col {
        flex: 1;

        &.col-type {
          flex: 0 0 150px;
        }
      }

      .table-pagination {
        @include pagination-compact;
      }
    }
  }

  .pane-footer {
    position: sticky;
    display: flex;
    bottom: 0;
    margin: 0 -24px;
    padding: 20px 24px;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: #fff;
    border-top: 1px solid $--light-gray;
    z-index: 5;

    .btn {
      min-width: 120px;
    }

    .rs-dropdown + .btn,
    .btn + .btn {
      margin-left: 16px;
    }

    .rs-btn {
      border-radius: .25rem;
    }

    .rs-dropdown-item-content {
      display: flex;
      align-items: center;

      svg {
        margin-left: 8px;
      }
    }

    .save-error {
      width: 100%;
      margin-bottom: 16px;
      color: $--accent;
      text-align: center;
    }
  }
}

.input-template-name {
  @include input-format;
}
