.event-planning-manager-component,
.event-planning-template-editor-component {
  @include rule-manager(840px);

  .pane-body {
    .sales-chart-container {
      height: 320px;
    }

    .rule-section {
      margin-top: 0;

      .rule-event-container {
        padding: 16px 0;
        box-shadow: inset 0px -1px 0px #DDDDDD;

        .timeframe-row {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 8px;

          .timeframe-wrapper {
            .template-note {
              margin-bottom: 4px;
              font-size: 12px;
              color: $--gray;
            }

            .timeframe-note {
              margin-bottom: 4px;
              font-weight: 700;
            }
          }
        }

        .rule-entry-wrapper {
          margin-top: 8px;

          .rule-entry-custom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .rule-entry-settings {
              display: flex;
              align-items: center;

              > svg {
                margin-left: 8px;
              }
            }

            .rule-selector {
              min-width: 150px;
            }

            .value-input {
              max-width: 150px;
            }

            .remove-rule-link {
              margin-left: 16px;
              color: $--red;
            }

            .rule-selector + .rule-selector,
            .rule-selector + .value-input {
              margin-left: 8px;
            }

            .rule-select__menu {
              // This should be greater than z-index of footer to be visible.
              z-index: 6;
            }
          }

          .rule-entry-ramp {
            display: flex;
            align-items: center;
            min-height: 36px;
            margin-top: 8px;

            > svg {
              margin-left: 8px;
            }

            .rs-picker-date {
              margin-left: 8px;
            }
          }
        }

        .error-message {
          margin-top: 8px;
          color: $--red;
        }
      }

      .section-footer {
        margin-top: 16px;
      }
    }
  }
}

.event-planning-template-selector-modal {
  @include rule-template-selector;
}
