.product-detail-keyword {
  margin-top: 6px;
  background: $--c6-white;
  box-shadow: 0px 1px 2px $--gray;
  padding: 18px 25px;

  .detail-keyword-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .keyword-tabs {
      display: flex;

      .tab {
        margin-right: 12px;
        padding-bottom: 3px;
        cursor: pointer;
        border-bottom: 2px solid $--c6-white;
        color: $--gray;

        &.selected, &:hover {
          color: $--c6-black;
          border-bottom: 2px solid $--c6-black;
        }
      }
    }
    /* media queries */
    @media (max-width: 576px) {
      flex-wrap: wrap;
      .date-range-component {
        margin-top: 5px;
      }
      .date-range-component, .date-range-component > div {
        width: 100%;
      }
    }
  }

  .table-keywords {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 80px;
      }

      &.col-keyword {
        flex: 0 0 160px;
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .meta-data {
          margin-top: 4px;
          font-size: 11px;
          color: $--dark-gray;
        }
      }

      &.col-genius-bid {
        color: $--c6-pink;

        > svg {
          margin-left: 4px;
          color: $--yellow;
        }
      }
    }
  }
}
