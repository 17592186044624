.column-editor {
  @include right-pane;
  width: 400px;

  .pane-note {
    margin-top: 4px;
    font-size: 12px;

    span {
      margin-right: 4px;
    }

    a {
      text-decoration: underline;
    }
  }

  .pane-body {
    overflow-y: auto;
    height: calc(100% - 100px);
    margin-top: 16px;

    .checkbox-component + .checkbox-component {
      margin-top: 16px;
    }

    .setting-item {
      position: relative;

      .pre-defined-template-select,
      .saved-template-select,
      .column-select {
        margin-top: 8px;
      }

      .setting-select-action {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;

        button {
          width: 85px;
        }
      }

      .new-setting {
        display: flex;
        justify-content: space-between;
        column-gap: 8px;
        margin-top: 8px;

        > input {
          @include input-format;
        }
      }
    }

    .setting-item + .setting-item {
      margin-top: 8px;
    }

    .column-sub-panel {
      position: relative;
      padding: 20px 10px;
      margin-top: 16px;
      border: 1px solid $--light-gray;
      border-radius: 4px;

      .sub-panel-title {
        position: absolute;
        top: -11px;
        left: 15px;
        padding: 0 5px;
        font-size: 15px;
        background: $--c6-white;
        font-weight: 700;
      }
    }

    .column-item-list {
      margin-bottom: 8px;
    }
  }

  .pane-footer {
    position: fixed;
    display: flex;
    bottom: 24px;
    width: 250px;
    align-items: center;
    justify-content: flex-start;

    .btn {
      min-width: 120px;
      margin-right: 8px;
    }
  }
}

.rs-list {
  box-shadow: none;
}

.column-item {
  padding: 10px;
  border-bottom: 1px solid $--light-gray;
  border-radius: 4px;
  box-shadow: none;

  .rs-list-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .column-label {
      margin-left: 20px;
      width: 100%;
    }

    .handle-column {
      color: $--c6-white;
    }

    .remove-column,
    .add-column {
      cursor: pointer;
      color: $--c6-white;

      &:hover {
        color: $--c6-blue;
      }
    }
  }

  &:hover {
    .handle-column,
    .remove-column,
    .add-column {
      color: $--c6-blue;
    }
  }
}
