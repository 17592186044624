// Used inside APM to render a compact version of paginator.
@mixin pagination-compact() {
  flex-direction: column;

  .pagination-left {
    margin-bottom: 8px;
  }

  .pagination-right {
    li.page-item {
      padding: 4px;
    }
  }
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;

  .pagination-left {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .pagination-right {
    display: flex;
    align-items: center;

    > ul {
      margin-bottom: 0;
      margin-left: -8px;
      li {
        display: inline-block;
        &:last-child {
          margin-right: 16px;
        }
      }
    }

    .prev-one {
      margin-right: 10px;
      cursor: pointer;
    }

    .prev-two {
      margin-right: 10px;
      letter-spacing: -5px;
      cursor: pointer;
    }

    .page-no {
      margin-right: 10px;
      cursor: pointer;
    }

    li.page-item {
      list-style: none;
      padding: 8px;
    }

    li.page-item.active {
      .page-link {
        color: black;
        background-color: #ced4da !important;
        border-color: #ced4da !important;
      }
    }

    .page-link {
      padding: 0.375rem 0.75rem;
      min-width: 2.5rem;
      border-radius: 4px;
      box-shadow: none !important;
      font-size: 13px;
      font-weight: 700;
      color: $--c6-black;
      text-align: center;

      &:hover {
        background-color: #ced4da !important;
      }
    }
  }

  select {
    height: 32px;
    border-color: rgb(204, 204, 204);
    background-color: $--c6-white;
    border-radius: 4px;
    padding: 4px 8px;
  }
}