.agency-overview-page {
  @include page-layout;

  .aggregated-view-report-date {
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;

    .date-range-container {
      min-width: 200px;

      .rs-picker-date {
        width: 100%;
      }
    }

    .rs-dropdown {
      a {
        color: #757575;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .table-accounts {
    @include sortable-table-format;

    .table-col {
      flex: 1;
      justify-content: center;
      flex-direction: column;

      &.account-name {
        font-weight: 600;
        min-width: 220px;
        align-items: flex-start !important;

      }

      .diff-value {
        display: inline-block;
        margin-top: 5px;
        padding: 0px 10px;
        min-width: 100px;
        border-radius: 10px;
        text-align: center;

        &.positive-trending {
          background-color: #00ff004a;
          color: #00a100;
        }

        &.negative-trending {
          background-color: rgba(250,92,124,.18);
          color: #fa5c7c;
        }

        &.neutral-trending {
          background-color: #e1dc324a;
          color: #dbaf08;
        }
      }
    }
  }

  .page-content {
    .report-widget {
      .report-widget-content {
        .metric-item {
          margin-top: 10px;
          margin-bottom: 10px;
          height: 110px;

          .metric-name {
            font-weight: 600;
            text-align: center;
          }

          .metric-item-content {
            display: flex;
            justify-content: center;

            .metric-values {
              margin-top: 15px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .metric-main-value {
                font-size: 30px;
              }

              .metric-diff-value {
                display: inline-block;
                margin-top: 5px;
                padding: 0px 10px;
                min-width: 100px;
                border-radius: 10px;
                text-align: center;

                &.positive-trending {
                  background-color: #00ff004a;
                  color: #00a100;
                }

                &.negative-trending {
                  background-color: rgba(250,92,124,.18);
                  color: #fa5c7c;
                }

                &.neutral-trending {
                  background-color: #e1dc324a;
                  color: #dbaf08;
                }
              }
            }

            .metric-trending-chart {
              width: 200px;
              height: 100px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}