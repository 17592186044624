.video-popup-modal {
  .flow-video-list {
    .flow-video-item {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;

      .youtube-player {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    .flow-video-item + .flow-video-item {
      margin-top: 16px;
    }
  }
}
