.inventory-manager-component,
.inventory-template-editor-component {
  @include rule-manager(840px);

  .pane-body {
    .rule-section {
      margin-top: 0;

      .rule-metric-container {
        padding: 16px 0 0;
        box-shadow: inset 0px -1px 0px #DDDDDD;

        .template-note {
          margin-bottom: 4px;
          font-size: 12px;
          color: $--gray;
        }

        .metric-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .metric-rule-section {
            display: flex;
            align-items: center;

            .metric-action-selector,
            .value-input {
              margin-left: 8px;
            }
          }

          .rule-select__control {
            min-width: 200px;
          }
        }

        .rule-sub-section {
          padding: 16px 0;
          border-top: 1px solid $--light-gray;

          .sub-section-name {
            margin-bottom: 8px;
            font-weight: 700;
            color: $--c6-black;
          }

          .sub-section-body {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .rule-settings-container {
              display: flex;
              align-items: center;

              .value-input {
                margin-left: 8px;
              }
            }

            .clear-link {
              font-size: 12px;
              color: #EA305C;
              cursor: pointer;
            }
          }

          .sub-section-error {
            margin-top: 4px;
            color: $--red;
          }
        }

        .value-input {
          max-width: 150px;
        }

        .rule-select__menu {
          // This should be greater than z-index of footer to be visible.
          z-index: 6;
        }
      }

      .section-footer {
        margin-top: 16px;
      }
    }
  }
}

.inventory-template-selector-modal {
  @include rule-template-selector;
}
