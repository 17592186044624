.campaign-detail-dashboard {
  margin-top: 8px;
  overflow: auto;

  .section {
    margin-bottom: 32px;
    background: #fff;
    box-shadow: $card-box-shadow;

    .statistics-cards {
      position: relative;
      margin-top: 16px;
      border-bottom: 1px solid $--light-gray;

      &.loading {
        opacity: 0.5;
      }

      .statistics-cards-row {
        display: flex;

        .statistics-card {
          width: 25%;
          height: 90px;
          padding-left: 25px;
          padding-top: 16px;
          border-left: 1px solid $--light-gray;
          overflow-x: auto;

          &:nth-child(1) {
            border-left: 0;
          }

          .statistics-card-name {
            font-size: 12px;
            font-weight: 400;
            color: $--dark-gray;
          }

          .statistics-card-value {
            font-size: 28px;
            font-weight: 400;
            color: $--c6-black;
          }
        }
      }

      .statistics-cards-row + .statistics-cards-row {
        border-top: 1px solid $--light-gray;
      }
    }

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 0;

      h4 {
        font-size: 16px;
      }
    }

    &.section-info {
      position: relative;
      padding: 24px;

      .sub-section-container {
        display: flex;
      }

      .sub-section {
        flex: 1;

        .section-title {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 600;

          > svg {
            margin-left: 8px;
          }
        }

        .input-row {
          > input {
            @include input-format;
            margin-top: 4px;
          }

          .bidding-strategy-selector {
            margin-top: 4px;
          }
        }

        .input-row + .input-row {
          margin-top: 16px;
        }

        .input-row-pair {
          display: flex;
          margin-top: 16px;

          .input-row + .input-row {
            margin-top: 0;
            margin-left: 16px;
          }
        }
      }

      .sub-section + .sub-section {
        margin-left: 16px;
      }

      .vertical-sub-section + .vertical-sub-section {
        margin-top: 16px;
      }

      .portfolio-info {
        .selected-portfolio {
          display: flex;
          align-items: center;
          padding-bottom: 8px;

          .btn {
            margin-left: 8px;
          }

          > svg {
            margin-left: 8px;
            cursor: pointer;
          }
        }

        .portfolio-actions {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .action-wrapper {
            flex: 1;

            > .btn {
              width: 100%;
              margin-bottom: 4px;
            }

            > input[type=text] {
              @include input-format;
            }
          }
        }

        .add-new, .add-existing {
          .action {
            padding-bottom: 5px;
            button {
              width: 100%;
            }
          }
          .content {
            width: 100%;
          }
        }
      }
    }

    .table-negatives {
      @include table-format;

      .table-col {
        flex: 1;
      }

      .col-level {
        flex: 0 0 180px;
      }

      .col-match-type,
      .col-type {
        flex: 0 0 120px;
      }
    }

    .table-logs {
      @include table-format;

      > .table-body {
        .table-row:not(.content-header) {
          height: auto;
          min-height: 70px;
          align-items: stretch;

          .table-col {
            height: auto;
          }
        }
      }

      .table-header-right {
        .category-selector,
        .type-selector {
          min-width: 180px;
        }

        .type-selector {
          margin-left: 8px;
        }

        .type-selector + .btn {
          margin-left: 8px;
        }
      }

      .table-col {
        flex: 1;
      }

      .col-type {
        flex: 0 0 200px;
      }

      .col-description {
        flex: 0 0 180px;
      }

      .col-contents {
        padding: 8px 0;
      }

      .col-created-at {
        flex: 0 0 64px;
      }

      .col-action {
        flex: 0 0 120px;
      }
    }

    .type-selector-container {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      padding: 0 16px;

      .btn + .btn,
      .btn + .rs-btn-toggle {
        margin-left: 16px;
      }
    }

    .stream-header-wrapper {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 8px;
      }
    }

    .mode-container {
      display: flex;
      align-items: center;

      .date-range-component {
        margin-right: 16px;
      }

      .rs-btn-toggle {
        margin-left: 16px;
      }
    }

    .stream-data-container {
      position: relative;
      min-height: 96px;
      padding: 18px 24px;

      .custom-table-component {
        @include table-format;

        .table-col {
          flex: 1;

          &.col-keyword {
            flex: 0 0 160px;
            flex-direction: column;
            align-items: flex-start!important;
            justify-content: center;

            .meta-data {
              margin-top: 4px;
              font-size: 11px;
              color: $--dark-gray;
            }
          }
        }
      }

      .table-target-stream-view {
        @include group-table-format;

        .child-table-wrapper {
          padding-left: 200px;
        }
      }
    }
  }
}

.add-product-adgroup-select {
  width: 100%;

  .adgroup-selector__menu {
    // z-index of search box is 4.
    z-index: 5;
  }
}

.add-negatives-modal,
.add-negative-to-campaigns-modal {
  @include modal-format;

  .nk-input-section {
    .input-field-wrapper {
      display: flex;
      align-items: center;

      label {
        margin-right: 8px;
        font-weight: 700;
      }
    }
  }

  .section-container {
    margin: 16px 0;

    &.no-padding {
      padding: 0;
    }

    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
    }

    .section-note {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: $--gray;
    }
  }

  .table-nks {
    @include table-format;

    .table-col {
      flex: 1;

      &.col-action {
        justify-content: flex-end;
      }
    }
  }

  .table-adgroups {
    @include sortable-table-format;
    @include campaign-name-cell;

    .table-col {
      flex: 1;

      &.col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .contents {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }

  textarea {
    margin: 8px 0;
    width: 100%;
    min-height: 72px;
    padding: 10px 12px;
    border: 1px solid $--light-gray;
    border-radius: 6px;
    resize: none;
  }
}

.add-targets-modal,
.add-target-to-campaigns-modal {
  @include modal-format;

  .keyword-input-section {
    .input-field-container {
      display: flex;

      .input-field-wrapper + .input-field-wrapper {
        margin-left: 16px;
      }
    }

    .input-field-wrapper {
      display: flex;
      align-items: center;

      label {
        min-width: 80px;
        margin-right: 8px;
        font-weight: 700;
      }

      > input {
        @include input-format;
      }
    }

    textarea {
      margin: 8px 0;
      width: 100%;
      min-height: 72px;
      padding: 10px 12px;
      border: 1px solid $--light-gray;
      border-radius: 6px;
      resize: none;
    }
  }

  .section-container {
    margin: 16px 0;

    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
    }

    .section-note {
      margin-top: 4px;
      font-size: 12px;
      font-weight: 400;
      color: $--gray;
    }
  }

  .table-keywords {
    @include table-format;

    .table-col {
      flex: 1;

      &.col-action {
        justify-content: flex-end;
      }

      > input {
        @include input-format;
      }
    }
  }

  .table-targets,
  .table-audiences {
    @include table-format;

    .table-header-right {
      label {
        min-width: 80px;
        margin-right: 8px;
      }

      > input {
        @include input-format;
      }
    }

    .table-col {
      flex: 1;

      &.col-target {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .category-path {
          margin-bottom: 4px;
          font-size: 12px;
          color: $--gray;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .targeting-name {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .targeting-meta {
          margin-top: 4px;
          font-size: 12px;
          color: $--gray;
        }
      }

      &.col-action {
        justify-content: flex-end;
      }

      > input {
        @include input-format;
      }
    }
  }

  .table-adgroups {
    @include sortable-table-format;
    @include campaign-name-cell;

    .table-col {
      flex: 1;

      &.col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .contents {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

.update-tags-modal {
  @include modal-format;

  .table-tags {
    @include table-format;
    @include campaign-name-cell;

    .table-header-right {
      column-gap: 8px;
    }

    .table-col {
      flex: 1;

      &.col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;
      }

      &.col-tag {
        .custom-tag-picker {
          width: 100%;
        }
      }
    }
  }
}
