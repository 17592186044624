.tree-list {
  .tree-item {
    .tree-contents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 0px;
      border-bottom: 1px solid #DDDDDD;

      .node-info {
        display: flex;
        align-items: center;
        background-color: transparent;

        svg {
          margin-right: 4px;
        }
      }

      .item-action {
        display: flex;
        align-items: center;

        .btn {
          margin-left: 4px;
        }
      }
    }

    .tree-child {
      padding-left: 16px;
    }
  }
}
