@mixin page-layout() {
  margin: 0 32px 60px;

  @media (max-width: 576px) {
    margin: 0 8px 8px;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-title {
      font-size: 24px;
    }
  }

  .page-content {
    margin-top: 24px;
  }
}
