.notes-panel {
  @include right-pane;
  width: 550px;

  .pane-body {
    overflow-y: auto;
    height: calc(100% - 100px);
    margin-top: 16px;

    .new-note {
      position: relative;

      .campaign-note-textarea {
        resize: none;
      }

      .note-save-btn {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    .note-timeline {
      margin-top: 16px;

      .rs-timeline-item {
        padding-left: 30px;

        &:first-child {
          .rs-timeline-item-tail {
            top: 15px;
          }
        }

        .rs-timeline-item-tail {
          left: 6px;
          top: 0px;
        }
        
        .rs-timeline-item-content {
          position: relative;

          .time-helper-text {
            font-size: 12px;
            color: $--soft-gray;
            font-style: italic;
          }

          .note-remove-btn {
            position: absolute;
            top: 5px;
            right: 5px;
          }
        }

        .rs-timeline-item-dot {
          svg {
            width: 16px;
          }
        }
      }
    }
  }

  .pane-footer {
    position: fixed;
    display: flex;
    bottom: 24px;
    width: 250px;
    align-items: center;
    justify-content: flex-start;

    .btn {
      min-width: 120px;
      margin-right: 8px;
    }
  }
}
