.job-checker-component {
  position: fixed;
  bottom: 16px;
  right: 32px;
  min-width: 450px;
  background: #fff;
  padding: 20px 24px;
  z-index: 100;
  box-shadow: 0px 2px 9px rgba(0, 83, 215, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.15);

  .pane-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pane-title {
      font-size: 16px;
      font-weight: 700;
      color: $--c6-black;
    }

    > svg {
      color: $--gray;
      cursor: pointer;
    }
  }

  .report-list {
    margin-top: 8px;
    padding: 4px 8px 0;
    border-top: 1px solid $--light-gray;

    .report-item {
      display: flex;
      align-items: center;
      padding: 4px;
      font-size: 12px;

      .report-name {
        flex: 1;
      }

      > a {
        margin-right: 8px;
      }

      .remove-icon {
        margin-left: 16px;
        color: $--gray;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .report-item + .report-item {
      border-top: 1px dashed $--light-gray;
    }
  }

  .pane-footer {
    margin-top: 4px;
    padding-top: 8px;
    border-top: 1px solid $--light-gray;
  }
}
