.product-detail-testing {
  margin-top: 6px;
  background: $--c6-white;
  box-shadow: 0px 1px 2px $--gray;

  .testing-header {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid $--light-gray;
    font-size: 12px;
  }

  .table-tests {
    @include table-format;

    .progress {
      background-color: $--yellow!important;
    }

    .table-col {
      flex: 1;
    }
  }
}

.testing-detail-modal,
.testing-add-modal {
  .test-meta {
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid $--light-gray;

    .test-name-label {
      margin-right: 8px;
    }

    input {
      @include input-format;
    }
  }

  .test-notes {
    display: flex;
    justify-content: space-between;

    .notes-column {
      flex: 1;

      .notes-header {
        padding-bottom: 8px;
        border-bottom: 1px solid $--light-gray;
        font-weight: 700;
      }
    }

    .notes-column + .notes-column {
      margin-left: 16px;
    }

    .notes-row {
      display: flex;
      margin-top: 8px;
    }

    .notes-row + .notes-row {
      margin-top: 8px;
    }

    .notes-box {
      width: 50%;
      margin-left: 4px;
      margin-right: 4px;
    }

    input {
      @include input-format;
    }

    textarea {
      width: 100%;
      height: 54px;
      padding: 10px 12px;
      border: 1px solid $--light-gray;
      border-radius: 6px;
    }
  }
}

.testing-detail-modal {
  .test-results {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 12px;

    .results-column {
      flex: 1;
    }

    .results-column + .results-column{
      margin-left: 16px;
    }

    .results-row {
      display: flex;
    }

    .results-row + .results-row {
      margin-top: 8px;
    }

    .results-box {
      width: 50%;
      padding: 8px 0;
      text-align: center;

      &.results-revenue {
        background: $--dark-red;
      }
      &.results-spend {
        background: #CFD2F5;
      }
      &.results-impression {
        background: $--green;
      }
      &.results-clicks {
        background: $--c6-pink;
      }
      &.results-ctr {
        background: $--dark-pink;
      }
      &.results-conversion {
        background: $--soft-blue;
      }
      &.results-acos {
        background: $--light-blue;
      }

      .results-value {
        display: block;
        margin-top: 4px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.testing-add-modal {
  .test-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .test-meta-left {
      display: flex;
      align-items: center;

      input {
        width: auto;
      }
    }

    .test-meta-right {
      display: flex;
      align-items: center;

      .btn {
        margin-right: 8px;
      }

      input {
        max-width: 64px;
        margin-right: 8px;
      }
    }
  }
}
