.campaign-detail-placement-op {
  .table-placements {
    @include sortable-table-format;

    .table-header-right {
      > input {
        @include input-format;
        width: auto;
        max-width: 120px;
        height: 36px;
      }

      > .btn + input {
        margin-left: 16px;
      }

      .placement-selector {
        margin-right: 8px;
      }

      .rs-dropdown-toggle {
        background-color: $--c6-blue;
        color: #fff;
      }

      > .dropdown-tooltip {
        margin-left: 4px!important;
        margin-right: 16px;

        &:hover {
          background: transparent!important;

          path {
            stroke: $--light-gray!important;
          }
        }
      }
    }

    .table-col {
      position: relative;
      flex: 1;

      &.col-placement,
      &.col-strategy {
        flex: 2;
      }

      .action-button-container {
        display: flex;
        position: absolute;
        top: 54px;
        z-index: 99;

        .btn {
          padding-top: .25rem;
          padding-bottom: .25rem;
        }

        .btn + .btn {
          margin-left: 4px;
        }
      }
    }

    .table-body {
      overflow-y: auto;
    }
  }
}

.placement-modal {
  @include modal-format;

  .rs-modal-title {
    justify-content: flex-start;

    .placement-modal-title {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  .optimize-option {
    opacity: 0.4;
    margin-bottom: 16px;

    &.active {
      opacity: 1;
    }

    .option-radio {
      margin-right: 8px;

      label {
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
      }
    }

    .option-content {
      display: flex;
      margin: 16px 0 0 24px;

      .option-item {
        width: 180px;
        margin-right: 24px;

        .rs-input-number,
        .bid-option-selector {
          margin-top: 8px;
          width: 110px;
        }
      }
    }

    .recommend-option {
      display: flex;
      align-items: center;
    }
  }
}
