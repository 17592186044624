.smart-select-wrapper {
  display: flex;
  align-items: center;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  > :first-child {
    flex: 1;
  }

  .btn {
    margin-left: 16px;
  }

  .sku-selector__menu,
  .campaign-selector__menu {
    // z-index of search box is 4.
    // This should be greater than z-index of footer to be visible.
    z-index: 6;
  }

  .sku-option {
    display: flex!important;
    min-width: 0;
    align-items: center;

    img,
    .placeholder-image {
      width: 32px;
      height: 32px;
      border-radius: 4px;
    }

    .placeholder-image {
      line-height: 32px;
    }

    .product-info {
      min-width: 0;
      margin-left: 16px;

      .product-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .product-sku {
        margin-top: 4px;
        color: $--gray;
      }
    }
  }

  .placeholder-image {
    display: inline-block;
    border: 1px solid $--gray;
    font-size: 9px;
    text-align: center;
    text-transform: uppercase;
  }

  .sku-selector__multi-value__label {
    img,
    .placeholder-image {
      width: 36px;
      height: 36px;
    }

    .placeholder-image {
      line-height: 36px;
    }
  }
}
