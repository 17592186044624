.target-search-container {
  .keyword-search-box {
    display: flex;
    align-items: center;
    column-gap: 8px;

    > input {
      @include input-format;
      max-width: 200px;
      height: 36px;
    }

    .btn {
      white-space: nowrap;
    }
  }

  .target-search-result-container {
    padding: 16px 24px;

    .tab-list {
      .tab {
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
        padding-bottom: 4px;
        background-color: transparent;
        border-bottom: 2px solid white;
        color: $--gray;

        &.selected,
        &:hover {
          color: $--c6-black;
          border-bottom-color: $--c6-black;
        }

        > svg {
          margin-left: 8px;
        }
      }
    }

    .section-label {
      margin-top: 16px;
      font-size: 16px;
    }

    .table-keywords,
    .table-targets,
    .table-search-terms,
    .table-grouped-keywords,
    .table-grouped-targets,
    .table-grouped-search-terms {
      .table-row {
        &:not(.content-footer) {
          .table-col {
            &.col-history {
              position: relative;
              flex-direction: column;
              align-items: flex-start!important;
              justify-content: center;
            }
          }
        }
      }
    }

    .table-grouped-keywords,
    .table-grouped-targets,
    .table-grouped-search-terms {
      @include group-table-format;
    }
  }
}

.target-search-container .target-search-result-container .table-keywords,
.target-search-container .target-search-result-container .table-targets,
.target-search-container .target-search-result-container .table-search-terms,
.dup-keyword-modal .table-keywords,
.dup-target-modal .table-targets,
.dup-search-term-modal .table-search-terms {
  @include sortable-table-format;

  .table-col {
    flex: 1;

    &:not(.col-check) {
      min-width: 80px;
    }
  }

  .table-row:not(.content-header) {
    @include campaign-name-cell;

    .col-campaign {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .contents {
        width: 100%;
      }
    }
  }
}

.target-search-container .target-search-result-container .table-keywords,
.target-search-container .target-search-result-container .table-targets,
.target-search-container .target-search-result-container .table-search-terms,
.target-search-container .target-search-result-container .table-grouped-keywords,
.target-search-container .target-search-result-container .table-grouped-targets,
.target-search-container .target-search-result-container .table-grouped-search-terms,
.dup-keyword-modal .table-keywords,
.dup-target-modal .table-targets,
.dup-search-term-modal .table-search-terms {
  .table-col {
    &.col-state {
      text-transform: capitalize;
    }

    &.col-campaign,
    &.col-adgroup {
      .contents {
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &.col-campaign,
    &.col-adgroup,
    &.col-keyword,
    &.col-target,
    &.col-search-term {
      flex: 0 0 160px;
    }

    &.col-keyword,
    &.col-target {
      flex-direction: column;
      align-items: flex-start!important;
      justify-content: center;

      .meta-data {
        margin-top: 4px;
        font-size: 11px;
        color: $--dark-gray;
      }
    }
  }
}

.target-search-container .target-search-result-container.dup-result-container {
  .table-keywords,
  .table-targets,
  .table-search-terms {
    .table-row {
      cursor: pointer;
    }
  }
}

.dup-keyword-modal {
  .filter-container {
    display: flex;
    align-items: center;
    margin: 16px;

    .select-wrapper {
      display: flex;
      align-items: center;

      > span {
        margin-right: 8px;
        font-weight: 700;
      }

      .match-type-selector__menu {
        // z-index of date picker is 5.
        z-index: 6;
      }
    }
  }
}
