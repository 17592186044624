.signup-complete-page {
  width: 100vw;
  height: 100vh;
  background-image: url(../../assets/img/signup-bg.png);
  background-position: top center;
  background-repeat: no-repeat repeat-y;
  background-size: 100% auto;
}

.signup-complete-modal {
  .rs-modal-body {
    margin-top: 24px;
    padding-bottom: 16px;

    &.overflow-none {
      overflow: initial !important;
    }
  }

  .modal-description {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 12px 16px;
    background-color: #f2f6fc;
    border-radius: .25rem;
    flex-direction: row;
    margin-top: 16px;

    :not(:first-child) {
      margin-top: 16px;
    }

    svg {
      margin-right: 8px;

      path {
        stroke: $--c6-blue;
      }
    }
  }

  .signup-section {
    .section-name {
      margin-bottom: 8px;
      font-weight: 600;

      &.billing-section-name {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        .credit-card-image {
          max-height: 24px;
        }
      }

      .amazon-account-select-tip {
        display: block;
        padding-left: 16px;
        padding-top: 5px;
        font-weight: 400;
      }

      .amazon-description {
        font-weight: 100;
        display: flex;
        align-items: flex-start;
        padding-left: 16px;
        padding-top: 2px;

        svg {
          margin-right: 8px;
          width: 25px;

          path {
            stroke: $--c6-blue;
          }
        }
      }
    }

    .field-row {
      display: flex;

      .field-wrapper {
        flex: 1;

        label {
          display: block;
          margin-bottom: 4px;
        }

        > input {
          @include input-format;
        }

        .input-pair {
          display: flex;

          > input {
            @include input-format;
            flex: 1;
          }

          > input + input {
            margin-left: 16px;
          }
        }

        .rs-picker-date {
          display: block;
        }
      }

      .field-wrapper + .field-wrapper {
        margin-left: 16px;
      }
    }
  }

  .signup-section + .signup-section {
    margin-top: 16px;
  }

  .coupon-container {
    margin-top: 16px;

    input {
      @include input-format;
      margin-top: 4px;
    }
  }

  .region-selector {
    display: flex;
    align-items: center;

    label {
      margin-right: 8px;
    }

    .region-list {
      flex: 1;

      .region-list__menu {
        // z-index of date picker is 5.
        z-index: 6;
      }
    }

    .btn {
      margin-left: 8px;

      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .stripe-wrapper {
    margin-top: 16px;
    padding: 8px;
    border: 1px solid $--light-gray;
    border-radius: .25rem;
  }

  .upsell-section {
    position: relative;
    margin-top: 16px;
    padding: 16px;
    background: linear-gradient(93.69deg, #0E2154 3.16%, #0F2154 96.49%);
    border-radius: 4px;
    color: #fff;

    &::before {
      position: absolute;
      content: "";
      top: 36px;
      width: 38px;
      height: 80px;
      background-image: url(../../assets/img/arrow-top.png);
      background-repeat: no-repeat;
      background-size: 38px auto;
      background-position: 0 16px;
      z-index: 0;
    }

    .upsell-contents {
      padding-left: 54px;
    }

    .upsell-header {
      margin-top: 8px;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 16px;
      text-decoration: underline;
    }

    .upsell-description {
      margin-bottom: 16px;

      .upsell-price {
        font-weight: 600;
        color: $--red;
      }
    }

    .upsell-warning {
      color: $--red;
    }
  }

  .table-accounts {
    @include table-format;

    .table-header-right {
      > div + div {
        margin-left: 8px;
      }
    }

    .table-col {
      flex: 1;

      &.col-country {
        flex: 0 0 54px;
      }

      &.col-type {
        flex: 0 0 54px;
        text-transform: capitalize;
      }

      &.col-name {
        flex: 0 0 180px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .rs-modal-footer {
    &.text-center {
      text-align: center!important;
    }

    .btn-signup {
      width: 100%;
    }

    a {
      display: inline-block;
      margin-top: 8px;
      color: $--soft-gray;
    }
  }
}
