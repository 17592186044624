.setting-page {
  @include page-layout;

  .page-content {
    box-shadow: $card-box-shadow;
    background: #fff;

    .page-section {
      position: relative;
      padding: 16px 24px;

      &.loading {
        opacity: 0.5;
      }

      .section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .section-title-contents {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          color: $--c6-black;

          svg {
            margin-left: 8px;
          }
        }
      }

      .section-note {
        margin-top: 4px;
        font-size: 12px;
        color: $--gray;
      }

      .section-contents {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .field-wrapper {
          > input {
            @include input-format;
          }
        }

        &.section-billing {
          display: block;

          .tab-list {
            display: flex;
            margin-bottom: 16px;
            border-bottom: 1px solid $--light-gray;

            .tab {
              margin-right: 16px;
              padding-bottom: 8px;
              font-size: 15px;
              background-color: transparent;
              color: $--gray;

              &.selected,
              &:hover {
                border-bottom: 2px solid $--c6-black;
                color: $--c6-black;
              }
            }
          }

          .billing-info-container {
            display: flex;

            .billing-info-details {
              min-width: 240px;
              margin-left: 16px;
              padding: 8px 16px;
              border: 1px solid $--light-gray;
              border-radius: 0.25rem;

              .billing-info-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                padding-bottom: 4px;
                border-bottom: 1px solid $--light-gray;

                > span {
                  font-weight: 700;
                }

                > a {
                  color: $--c6-black;
                  font-size: 13px;

                  &:active {
                    text-decoration: none;
                  }

                  svg {
                    vertical-align: middle;
                  }
                }
              }
            }
          }

          .card-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 240px;
            height: 150px;
            margin-right: 16px;
            padding: 16px;
            background-color: #374454;
            border-radius: 4px;
            color: #fff;

            .card-header {
              display: flex;
              align-items: center;

              .card-brand {
                flex: 1;
              }

              .card-status {
                margin-left: 8px;
                font-size: 11px;
              }

              .card-edit {
                color: #fff;
              }
            }

            .card-number {
              font-size: 14px;
              font-weight: 700;
            }

            .card-info {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .card-info-field {
                div:nth-child(1) {
                  font-size: 11px;
                  font-weight: 400;
                }

                div:nth-child(2) {
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }
          }

          .table-invoices {
            @include table-format;

            .table-col {
              flex: 1;

              &.col-status {
                flex: 0 0 64px;
              }

              &.col-date {
                flex: 0 0 128px;
              }

              &.col-download {
                flex: 0 0 128px;
              }

              .invoice-paid-at {
                margin-left: 8px;
                color: $--gray;
              }
            }

            .table-pagination {
              input {
                height: auto;
              }
            }
          }

          .table-subscriptions {
            @include table-format;

            > .table-body {
              .table-row:not(.content-header) {
                height: auto;
                min-height: 70px;
                align-items: stretch;

                .table-col {
                  height: auto;
                }
              }
            }

            .table-col:only-child {
              flex: 1;
            }

            .col-no {
              flex: 0 0 48px;
            }

            .col-account {
              flex: 0 0 216px;
              flex-direction: column;
              align-items: flex-start!important;
              justify-content: center;
            }

            .col-status {
              flex: 0 0 96px;

              &.status-active {
                color: $--green;
              }

              &.status-cancelled {
                color: $--red;
              }
            }

            .col-amount {
              flex: 0 0 128px;

              ul {
                margin-bottom: 0;
              }
            }

            .col-timeline {
              flex: 1;

              ul {
                margin: 8px 0;
                padding-left: 16px;
              }
            }

            .col-action {
              flex: 0 0 96px;
              text-align: right;

              .link-cancel {
                color: $--red;
              }
            }

            .table-pagination {
              input {
                height: auto;
              }
            }
          }
        }

        &.section-universal {
          .field-wrapper {
            margin-right: 16px;

            label {
              display: flex;
              align-items: center;

              svg {
                margin-left: 8px;
              }
            }

            input {
              margin-top: 4px;
            }

            .timezone-selector {
              min-width: 240px;
              margin-top: 4px;
            }
          }

          .btn {
            margin-top: 23px;
          }
        }

        &.section-notification {
          .checkbox-component {
            margin-right: 16px;
          }
        }
      }

      .table-accounts {
        @include table-format;
        margin-top: 16px;

        .table-header-right {
          column-gap: 8px;
        }

        .table-body {
          .table-row {
            &.content-header {
              .table-col {
                height: 48px;
              }
            }

            &.connection-row {
              min-height: 36px;
              font-size: 11px;
              color: $--c6-black;
              background-color: #F9FAFB;

              .table-col {
                height: 36px;
                background-color: #F9FAFB;

                &.col-connection {
                  color: $--gray;
                  text-transform: uppercase;
                }
              }
            }

            &.selection-row {
              min-height: 48px;

              .table-col {
                height: 48px;
                background-color: #FDF9ED;
                justify-content: center;
              }
            }
          }
        }

        .table-col {
          flex: 1;

          a.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        .col-brand-name {
          column-gap: 4px;

          input {
            @include input-format;
          }
        }

        .col-connection-status {
          .rs-btn-toggle {
            margin-right: 4px;
          }
        }

        .col-type {
          text-transform: capitalize;
        }

        .col-subscription {
          .state-active {
            color: $--green;
          }

          .state-cancelled {
            color: $--red;
          }

          .state-cancelled + a {
            margin-left: 8px;
          }
        }

        .col-ads-api,
        .col-sp-api {
          column-gap: 8px;
        }
      }
    }

    .page-section + .page-section {
      border-top: 1px solid $--light-gray;
    }
  }
}

.settings-card-modal {
  .field-wrapper {
    .required {
      color: $--red;
    }

    input {
      @include input-format;
      margin-top: 4px;
    }
  }

  .field-wrapper + .field-wrapper {
    margin-top: 8px;
  }

  .stripe-wrapper {
    margin-top: 16px;
  }
}

.settings-billing-modal {
  .field-row {
    display: flex;
    align-items: center;
  }

  .field-row + .field-row {
    margin-top: 8px;
  }

  .field-wrapper {
    flex: 1;

    .required {
      color: $--red;
    }

    > input {
      @include input-format;
      margin-top: 4px;
    }
  }

  .field-wrapper + .field-wrapper {
    margin-left: 16px;
  }
}

.settings-cancellation-modal {
  input {
    @include input-format;
    margin-top: 8px;
  }
}

.settings-account-add-modal {
  .rs-modal-body {
    min-height: 200px;
  }

  .region-selector {
    display: flex;
    align-items: center;

    label {
      margin-right: 8px;
    }

    .region-list {
      min-width: 240px;

      .region-list__menu {
        // z-index of search box is 4.
        z-index: 5;
      }
    }

    .btn {
      margin-left: 8px;

      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .table-accounts {
    @include table-format;

    .table-header-right {
      > div + div {
        margin-left: 8px;
      }
    }

    .table-row.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .table-col {
      flex: 1;

      &.col-country {
        flex: 0 0 72px;
      }

      &.col-type {
        flex: 0 0 72px;
        text-transform: capitalize;
      }

      &.col-name {
        flex: 0 0 240px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .coupon-container {
    margin-top: 16px;

    label {
      margin-right: 8px;
    }
  }
}
