.main-layout {
  display: flex;
  height: 100%;

  .main-content {
    flex: 1;
    height: 100%;
    background-color: $--soft-white;
    overflow-y: scroll;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #304C65;
    opacity: 0.45;
    z-index: 6;
  }
}

#c6_navbar {
  width: 100%;
  height: 48px;
  background: #fff;
  z-index: 1000;
}
