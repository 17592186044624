.bulk-op-container {
  .table-skus,
  .table-keywords,
  .table-targets,
  .table-search-terms,
  .table-negatives,
  .table-negative-keywords,
  .table-negative-targets {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 80px;
      }
    }

    .table-row:not(.content-header) {
      @include campaign-name-cell;

      .col-campaign {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .contents {
          width: 100%;
        }
      }
    }
  }

  .table-skus,
  .table-grouped-skus {
    .table-col {
      &.col-campaign {
        flex: 0 0 160px;
      }

      &.col-adgroup {
        min-width: 120px;
      }

      &.col-adgroup,
      &.col-campaign {
        .contents {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-sku {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        > strong {
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .meta-data {
          margin-top: 4px;
          font-size: 11px;
          color: $--dark-gray;
        }
      }

      img {
        max-height: 48px;
      }
    }
  }

  .target-op-result-container,
  .advanced-negative-result-container {
    padding: 16px 24px;

    .tab-list {
      .tab {
        display: inline-flex;
        align-items: center;
        margin-right: 16px;
        padding-bottom: 4px;
        background-color: transparent;
        border-bottom: 2px solid white;
        color: $--gray;

        &.selected,
        &:hover {
          color: $--c6-black;
          border-bottom-color: $--c6-black;
        }

        > svg {
          margin-left: 8px;
        }
      }
    }

    .tab-list + .result-description {
      margin-top: 16px;
    }
  }

  .table-keywords,
  .table-targets,
  .table-grouped-keywords,
  .table-grouped-targets {
    .table-col {
      &.col-campaign,
      &.col-adgroup {
        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-campaign,
      &.col-adgroup {
        flex: 0 0 160px;
      }

      &.col-keyword {
        flex: 0 0 160px;
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .meta-data {
          margin-top: 4px;
          font-size: 11px;
          color: $--dark-gray;
        }
      }

      &.col-target {
        flex: 0 0 210px;

        > img {
          max-width: 48px;
          margin-right: 8px;
        }

        .target-info {
          flex-direction: column;
          align-items: flex-start!important;
          justify-content: center;
        }

        .target-text-wrapper {
          display: flex;
          align-items: center;

          > strong {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          > a {
            margin-left: 4px;
          }
        }

        .meta-data {
          margin-top: 4px;
          font-size: 11px;
          color: $--dark-gray;
        }
      }

      &.col-genius-bid {
        min-width: 120px;
        color: $--c6-pink;

        > svg {
          margin-left: 4px;
          color: $--yellow;
        }
      }
    }
  }

  .table-search-terms,
  .table-grouped-search-terms {
    .table-col {
      &.col-search-term,
      &.col-campaign,
      &.col-adgroup,
      &.col-target {
        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-search-term,
      &.col-campaign,
      &.col-adgroup {
        flex: 0 0 160px;
      }

      &.col-search-term {
        > img {
          max-width: 48px;
          margin-right: 8px;
        }
      }

      &.col-target {
        flex: 0 0 140px;
      }
    }
  }

  .table-negative-keywords,
  .table-negative-targets,
  .table-grouped-negative-keywords,
  .table-grouped-negative-targets {
    .table-col {
      &.col-campaign,
      &.col-adgroup,
      &.col-keyword,
      &.col-target {
        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-target {
        flex: 0 0 220px;
      }

      &.col-campaign {
        flex: 0 0 160px;
      }

      &.col-target {
        > img {
          max-width: 48px;
          margin-right: 8px;
        }

        .contents {
          width: auto;
        }

        .contents + a {
          margin-left: 8px;
        }
      }
    }
  }

  .table-negatives,
  .table-grouped-negatives,
  .table-grouped-negatives-by-words {
    .table-col {
      &.col-word,
      &.col-campaign,
      &.col-adgroup {
        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-campaign {
        flex: 0 0 160px;
      }

      &.col-adgroup {
        flex: 0 0 120px;
      }

      &.col-word {
        flex: 0 0 96px;
        cursor: pointer;
      }
    }
  }

  .table-grouped-search-terms,
  .table-grouped-skus,
  .table-grouped-keywords,
  .table-grouped-targets,
  .table-grouped-negatives,
  .table-grouped-negatives-by-words,
  .table-grouped-negative-keywords,
  .table-grouped-negative-targets {
    @include group-table-format;
  }

  .table-grouped-negatives-by-words {
    .child-table-wrapper {
      padding-left: 120px + 64px;
    }
  }

  .table-placements {
    @include group-table-format;

    .table-header-right {
      .rs-dropdown-toggle {
        background-color: $--c6-blue;
        color: #fff;
      }

      > .dropdown-tooltip {
        margin-left: 4px!important;
        margin-right: 16px;

        &:hover {
          background: transparent!important;

          path {
            stroke: $--light-gray!important;
          }
        }
      }
    }

    .table-col {
      &.col-campaign {
        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &.col-strategy {
        flex: 0 0 200px;
      }

      &.col-base-bid {
        flex: 0 0 120px;
      }

      &.col-acos {
        flex: 0 0 96px;
      }

      &.col-placement {
        flex: 0 0 200px;
        font-weight: 700;
      }
    }

    .child-table-wrapper {
      padding-left: 80px + 64px;
      border-bottom: 3px solid #dddddd;

      .custom-table-component   {
        > .table-body {
          .table-row:last-child {
            .table-col {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.negative-creator-modal {
  .view-link {
    margin-left: 16px;
    font-size: 13px;
    font-weight: 400;
    text-decoration: underline;
  }

  .section-wrapper {
    display: flex;
    margin-top: 16px;
  }

  .section-container {
    flex: 1;
    margin: 16px 0;

    .section-title {
      margin-bottom: 8px;
      font-weight: 600;
    }

    > .checkbox-component {
      margin-left: 8px;
      margin-bottom: 8px;
    }

    .rs-radio-checker {
      small {
        display: block;
        margin-top: 4px;

        &.warning {
          color: $--red;
        }
      }
    }
  }

  .section-container + .section-container {
    margin-left: 16px;
  }
}

.negative-details-modal {
  .table-search-terms {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 72px;

        &.col-search-term {
          min-width: 160px;
        }

        &.col-campaign {
          min-width: 160px;
        }
      }

      &.col-search-term,
      &.col-campaign,
      &.col-adgroup {
        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .table-row:not(.content-header) {
      @include campaign-name-cell;

      .col-campaign {
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;

        .contents {
          width: 100%;
        }
      }
    }
  }
}
