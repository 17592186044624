.table-filter-pane {
  @include right-pane();
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .pane-header {
    margin-bottom: 16px;
  }

  .pane-filter-select {
    margin: 10px 0;
    padding: 10px 0;
    border-top: 1px solid #ddd;

    .filter-select-action {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      button {
        width: 100px;
      }
    }

    .new-filter {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      align-items: center;
      column-gap: 8px;

      > input {
        @include input-format;
      }
    }
  }

  .pane-body {
    overflow-y: auto;
    height: 100%;
    padding-right: 10px;

    .filter-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      & > span {
        margin-bottom: 4px;
      }

      .checkbox-wrapper {
        display: flex;

        > svg {
          margin-left: 8px;
        }
      }

      .filter-row-child {
        display: flex;
        justify-content: space-between;

        input {
          @include input-format;
        }

        input + input {
          margin-left: 16px;
        }
      }
    }
  }

  .pane-footer {
    display: flex;
    bottom: 24px;
    align-items: center;
    justify-content: space-between;
    width: 330px;

    .btn {
      min-width: 100px;
    }
  }
}
