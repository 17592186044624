.comparison-view-container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  min-height: 96px;
  margin-top: 16px;
  padding: 16px 24px;

  .summary-card {
    flex: 1;

    &.summary-metric {
      font-weight: 600;
    }

    .summary-heading {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $--light-gray;
      font-weight: 600;
    }

    .metric-value {
      display: flex;
      align-items: center;
      margin-top: 4px;

      > svg {
        margin-left: 8px;
      }
    }
  }
}
