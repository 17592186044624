@mixin modal-format() {
  .rs-modal-title {
    display: flex;
    justify-content: center;

    > button {
      margin-left: 8px;
    }
  }
}
