.product-detail-dashboard {
  &.loading {
    opacity: 0.5;
  }

  .section {
    background: #fff;
    box-shadow: 0px 1px 2px $--gray;
    margin-bottom: 24px;

    .meta-container {
      display: flex;

      .meta-card {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 16px 24px;

        .meta-label {
          margin-bottom: 8px;
          font-size: 12px;
          color: $--dark-gray;
        }

        input {
          @include input-format;
        }

        .meta-value {
          font-size: 28px;
          color: $--c6-black;
          line-height: 38px;
        }

        .btn {
          margin-top: 4px;
        }
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;

        .meta-card {
          width: 50%;
        }
      }

      @media (max-width: 375px) {
        .meta-card {
          width: 100%;
        }
      }
    }

    .kpi-container {
      display: flex;
      flex-wrap: wrap;

      .kpi-card {
        display: flex;
        flex-direction: column;
        width: 25%;
        padding: 16px 24px;
        border: 1px solid $--light-gray;

        .metric-name {
          margin-bottom: 8px;
          font-size: 12px;
          color: $--dark-gray;
        }

        .metric-value {
          font-size: 28px;
          color: $--c6-black;
          line-height: 38px;
        }
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;

        .kpi-card {
          width: 50%;
        }
      }

      @media (max-width: 375px) {
        .kpi-card {
          width: 100%;
        }
      }
    }

    .type-selector-container {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      padding: 0 16px;

      .btn + .btn {
        margin-left: 16px;
      }
    }
  }

  .table-campaigns {
    @include sortable-table-format;

    .table-filter-shower {
      padding-top: 8px;
      padding-left: 16px;
    }

    .table-col {
      flex: 1;

      &.col-campaign {
        min-width: 240px;
        flex-direction: column;
        align-items: flex-start!important;
        justify-content: center;
      }
    }

    .campaign-status {
      display: flex;
      font-size: 10px;
      margin-bottom: 4px;

      .status {
        display: flex;
        align-items: center;
        margin-right: 8px;

        .bullet {
          display: inline-block;
          width: 4px;
          height: 4px;
          margin-right: 4px;
          border-radius: 50%;
        }

        &.on {
          span {
            color: #2EB73C;
          }

          .bullet {
            background: #2EB73C;
          }
        }

        &.off {
          span {
            color: $--accent;
          }

          .bullet {
            background: #DDADAA;
          }
        }
      }

      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .campaign-name {
      width: 100%;
      margin-bottom: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .campaign-detail {
      font-size: 11px;
      color: $--dark-gray;

      span + span {
        margin-left: 8px;
      }
    }
  }
}