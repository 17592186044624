.campaign-detail-keyword-cleaner {
  .results-container {
    padding-top: 16px;

    .results-tables {
      display: flex;
      padding-top: 16px;
      flex-wrap: wrap;

      .table-content {
        flex: 1;
        padding: 5px;
      }

      @media (max-width: 576px) {
        flex-direction: column;

        .table-content {
          width: 100%;
        }
      }
    }
  }

  .table-keywords {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 80px;
      }

      &.col-keyword {
        flex: 2;
        min-width: 120px;
      }
    }
  }

  .table-search-terms {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &.col-search-term {
        flex: 2;
        min-width: 120px;
      }
    }
  }
}
