.tutorial-page {
  @include page-layout;

  .page-title {
    img {
      width: 100%;
      height: auto;
    }
  }

  .tutorial-link {
    margin-left: auto;
    margin-top: 4px;
  }

  .page-content {
    margin-bottom: 24px;
    margin-top: 10px;
    padding: 16px 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: $card-box-shadow;
  }

  .flow-container {
    padding: 0 24px;

    .flow-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      cursor: pointer;

      .flow-name {
        border-bottom: 1px dashed;
        font-size: 16px;
        font-weight: 600;
        color: $--c6-black;

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      .coin-balance {
        .coin-label {
          margin-right: 8px;
        }

        display: inline-flex;
        align-items: center;
        padding: 2px 4px;
        border: 1px solid $--light-gray;
        border-radius: 4px;
      }
    }

    .flow-body {
      padding: 16px 0;

      .description {
        margin-bottom: 16px;
      }

      .video-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
        margin-right: -16px;

        .video-item {
          position: relative;
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 16px;
          padding-left: 16px;
          padding-right: 16px;

          .video-name {
            margin-bottom: 8px;
            font-size: 13px;
            color: $--c6-blue;
          }

          > img {
            width: 100%;
            cursor: pointer;
          }

          .youtube-play-btn {
            position: absolute;
            top: calc(50% - 8px);
            left: calc(50% - 21px);
          }
        }
      }

      .flow-timestamps {
        padding-bottom: 8px;
        border-bottom: 1px $--gray dashed;
        font-size: 12px;

        .timestamp-section {
          .title {
            margin-bottom: 4px;
            font-weight: 700;
          }

          .content {
            .timestamp + .timestamp {
              margin-left: 16px;
            }
          }
        }

        .timestamp-section + .timestamp-section {
          margin-top: 8px;
        }
      }

      .flow-text {
        ol {
          list-style: none;
          padding-left: 8px;

          li {
            padding: 4px 0px;
          }
        }
      }

      .attachment-list {
        .attachment-item {
          display: inline-flex;
          align-items: center;

          a {
            margin-left: 8px;
            text-decoration: underline;
          }
        }

        .attachment-item + .attachment-item {
          margin-left: 32px;
        }
      }

      .attachment-list + .attachment-list,
      .attachment-list + .video-list,
      .flow-timestamps + .flow-text,
      .flow-text + .attachment-list,
      .attachment-list + .flow-text,
      .flow-text + .video-list {
        margin-top: 16px;
      }
    }
  }

  .flow-container + .flow-container {
    border-top: 2px solid $--light-gray;
  }
}

.welcome-dialog {
  width: 960px;

  .rs-modal-content {
    display: flex;
    padding: 0;
    overflow: hidden;

    .left-section {
      position: relative;
      flex: 3;
      padding-top: 24px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background-image: url('../../assets/svg/login/background.svg');
      background-size: cover;

      .left-bg {
        position: absolute;
        top: 0;
      }

      .timer-image-wrapper {
        margin-top: -16px;
        text-align: center;
      }

      .timer-contents {
        margin-top: 32px;
        margin-bottom: 32px;
        color: #fff;
        text-align: center;

        .timer-heading {
          font-size: 20px;
        }

        .timer-subheading {
          font-size: 12px;
        }

        .timer-value {
          font-size: 60px;
        }
      }
    }

    .right-section {
      display: flex;
      flex: 2;
      flex-direction: column;
      justify-content: center;
      padding: 48px;
      color: #000;

      .welcome-heading {
        font-size: 36px;
        font-weight: 800;
        color: $--c6-black;
      }

      .welcome-email {
        margin-top: 5px;
      }

      .welcome-subheading {
        margin-top: 32px;
        font-size: 18px;
        font-weight: 600;
      }

      .welcome-description {
        margin-top: 16px;
      }

      .button-container {
        margin-top: 64px;
        text-align: right;
      }
    }
  }
}

@media (max-width: 576px) {
  .tutorial-page {
    .video-list {
      flex-direction: column;

      .video-item {
        width: 100% !important;
      }
    }

    .attachment-list {
      display: flex;
      flex-direction: column;

      .attachment-item + .attachment-item {
        margin-top: 8px;
        margin-left: 0!important;
      }
    }
  }
}