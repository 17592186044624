.activity-log {
  @include page-layout;

  .page-header {
    .page-title {
      display: flex;
      align-items: center;
      svg {
        margin-left: 16px;
      }
    }
  }

  .page-content {
    position: relative;
    margin-bottom: 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: $card-box-shadow;

    &.loading {
      opacity: 0.5;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0;
    border-bottom: 1px solid $--light-gray;

    .tab-container {
      padding-top: 16px;

      a {
        display: inline-block;
        padding: 0 8px 16px;
        font-size: 15px;
        color: $--gray;

        &.active,
        &:hover {
          border-bottom: 2px solid $--c6-black;
          color: $--c6-black;
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      a + a {
        margin-left: 16px;
      }
    }

    .filter-container {
      display: flex;
      column-gap: 8px;
      padding-bottom: 16px;

      .type-selector {
        min-width: 200px;
      }

      .btn {
        height: 36px;
      }
    }
  }

  .table-logs {
    @include table-format;

    > .table-body {
      .table-row:not(.content-header) {
        height: auto;
        min-height: 70px;
        align-items: stretch;

        &.log-sub-row {
          min-height: 48px;
        }

        &:not(.log-sub-row) {
          cursor: pointer;
        }

        .table-col {
          height: auto;
        }
      }
    }

    .table-col {
      flex: 1;
    }

    .col-expand {
      flex: 0 0 36px;
      min-width: 52px;
      text-align: center;

      .expand-icon {
        display: inline-block;
        width: 20px;
        border: 1px solid $--light-gray;
      }
    }

    .col-type {
      flex: 0 0 200px;
    }

    .col-campaign {
      flex: 0 0 240px;

      svg {
        margin-left: 8px;
        color: #575757;
        vertical-align: middle;
      }
    }

    .col-description {
      flex: 0 0 180px;
    }

    .col-contents {
      padding: 8px 0;
    }

    .col-created-at {
      flex: 0 0 72px;
    }

    .col-action {
      flex: 0 0 120px;
    }
  }
}
