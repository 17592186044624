.dashboard-page {
  @include page-layout;

  .page-header {
    .kpi-header-left {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .ad-type-selector {
      min-width: 270px;
    }

    .kpi-header-right {
      display: flex;
      font-size: 12px;

      .kpi-button {
        margin-right: 8px;
        padding: 6px 12px;
        border: 1px solid $--light-gray;
        border-radius: 6px;
        color: $--dark-gray;
      }

      .new-campaign-toggle {
        background-color: $--accent!important;
        color: #fff!important;
      }
    }
  }

  .kpi-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-left: -16px;
    margin-right: -16px;

    &.loading {
      opacity: .5;
    }

    .kpi-card {
      width: 25%;
      padding: 8px 16px;

      .kpi-card-inner {
        min-height: 98px;
        padding: 16px 24px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: $card-box-shadow;
        overflow-x: auto;

        &.loading {
          opacity: 0.5;
        }

        &.kpi-empty {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border: 1px dashed $--light-gray;
          box-shadow: none;
          color: $--gray;
          overflow: visible;
          cursor: pointer;

          .metric-selector {
            min-width: 80%;

            .metric-selector__menu {
              // z-index of date range picker is 5, and this should be
              // put above it.
              z-index: 6;
            }
          }
        }

        .metric-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: $--dark-gray;
          white-space: nowrap;

          svg:hover {
            path {
              stroke: $--c6-black;
            }
          }

          .trash-icon {
            cursor: pointer;
          }
        }

        .metric-value {
          display: flex;
          align-items: center;
          margin-top: 8px;
          font-size: 28px;
          color: $--c6-black;
          white-space: nowrap;

          > svg {
            margin-left: 8px;
          }
        }
      }
    }

    .plus-icon-container {
      width: 36px;
      height: 36px;
      margin: 8px 16px;
      border-radius: 2px;
      background-color: $--c6-blue;
      font-size: 24px;
      color: #fff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;

      .kpi-card {
        width: 50%;
      }
    }

    @media (max-width: 375px) {
      .kpi-card {
        width: 100%;
      }
    }
  }

  .type-selector-container {
    margin-top: 16px;

    .btn + .btn {
      margin-left: 16px;
    }
  }

  .comparison-view-container {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: $card-box-shadow;
  }
}