.dayparting-manager-component,
.dayparting-template-editor-component {
  @include rule-manager(840px);

  .top-container {
    .tab-selector {
      margin-top: 16px;
      border-bottom: 1px solid $--light-gray;

      > span {
        display: inline-block;
        padding: 8px;
        color: $--gray;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid $--c6-black;
          color: $--c6-black;
        }
      }
    }
  }

  .pane-body {
    .slot-selector {
      .note-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .slot-selector-note {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: $--gray;

          .filled-color {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 4px;
            border-radius: 2px;

            &.filled-green {
              background-color: $--green;
            }

            &.filled-yellow {
              background-color: $--yellow;
            }
          }

          > svg {
            margin-left: 4px;
          }
        }

        .slot-selector-note + .slot-selector-note {
          margin-top: 4px;
        }

        .action-container {
          .btn + .rs-btn-toggle {
            margin-left: 8px;
          }
        }
      }

      .filter-container {
        display: flex;
        margin-top: 16px;

        .filter-wrapper {
          flex: 1;

          .filter-value {
            display: flex;
            align-items: center;
            margin-top: 4px;

            > input {
              @include input-format;
              flex: 1;
              max-width: 96px;
              margin-left: 8px;
            }
          }
        }

        .filter-wrapper + .filter-wrapper {
          margin-left: 16px;
        }
      }

      .range-selector {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        .checkbox-component {
          font-size: 12px;

          .bullet {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 4px;
            border-radius: 50%;
          }
        }
      }

      .slot-list {
        margin-top: 16px;

        .dow-label,
        .time-label {
          font-size: 9px;
          color: #717171;
          cursor: pointer;
        }

        .dow-label {
          width: 12px;
        }

        .time-label {
          flex: 1;
          text-align: center;
        }

        .time-label + .time-label {
          margin-left: 4px;
        }

        .slot-row {
          display: flex;
          align-items: center;

          .slot-stripe {
            flex: 1;
            display: flex;
            align-items: center;
          }
        }

        .slot-row + .slot-row {
          margin-top: 4px;
        }

        .slot-wrapper {
          flex: 1;
          display: inline-block;
          position: relative;
          height: 27px;
          border-radius: 2px;
          font-size: 11px;
          font-weight: 700;
          line-height: 27px;
          text-align: center;
          cursor: pointer;

          .select-indicator {
            position: absolute;
            display: inline-block;
            top: 3px;
            right: 3px;
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
          }
        }

        .slot-wrapper + .slot-wrapper {
          margin-left: 4px;
        }
      }
    }

    .rule-section {
      .section-note {
        margin-top: 8px;
        font-size: 12px;
        color: $--gray;
      }

      .rule-sub-section {
        padding: 16px 0;
        box-shadow: inset 0px -1px 0px #DDDDDD;

        &:last-child {
          box-shadow: none;
        }

        .sub-section-name {
          margin-bottom: 8px;
          font-weight: 700;
          color: $--c6-black;
        }

        .sub-section-body {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .rule-settings-container {
            display: flex;
            align-items: center;

            .value-input {
              margin-left: 8px;
              max-width: 160px;
            }
          }

          .clear-link {
            font-size: 12px;
            color: #EA305C;
            cursor: pointer;
          }
        }

        .sub-section-error {
          margin-top: 4px;
          color: $--red;
        }
      }

      .rule-select__menu {
        // This should be greater than z-index of footer to be visible.
        z-index: 6;
      }
    }
  }

  .pane-footer {
    .clear-link {
      position: absolute;
      top: 20px;
      left: 24px;
    }
  }
}

.dayparting-template-selector-modal {
  @include rule-template-selector;

  .template-details {
    .selection-shower {
      margin-left: -2px;
      margin-right: -2px;

      .slot-display {
        display: inline-block;
        width: 20%;
        padding: 2px;

        .slot-inner {
          display: inline-block;
          width: 100%;
          padding: 4px 6px;
          background-color: rgba(22, 117, 224, 0.1);
          border: 1px solid rgba(22, 117, 224, 0.5);
          border-radius: 4px;
          font-size: 11px;
          color: #1675E0;
        }
      }
    }
  }
}
