.rank-manager-component {
  @include rule-manager(840px);

  .pane-body {
    .rule-section {
      .rule-metric-container {
        padding: 16px 0 0;
        box-shadow: inset 0px -1px 0px #DDDDDD;
      }

      .metric-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      .campaign-selector-wrapper {
        margin-top: 16px;

        .checkbox-component {
          margin-bottom: 8px;
        }
      }

      .keyword-selector {
        margin-top: 16px;
      }

      .keyword-selector__menu,
      .rule-select__menu {
        // This should be greater than z-index of footer to be visible.
        z-index: 6;
      }

      .rank-criteria-container,
      .rule-days-container {
        display: flex;
        align-items: center;

        .rs-input-number {
          max-width: 120px;
        }

        label + .rs-input-number,
        .rs-input-number + label {
          margin-left: 8px;
        }
      }

      .rank-criteria-container {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .rule-days-container {
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid $--light-gray;
      }

      .rule-sub-section {
        padding: 16px 0;
        border-top: 1px solid $--light-gray;

        .sub-section-name {
          margin-bottom: 8px;
          font-weight: 700;
          color: $--c6-black;
        }

        .sub-section-body {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .rule-settings-container {
            display: flex;
            align-items: center;

            .value-input {
              margin-left: 8px;
            }
          }

          .clear-link {
            font-size: 12px;
            color: #EA305C;
            cursor: pointer;
          }
        }

        .sub-section-error {
          margin-top: 4px;
          color: $--red;
        }
      }

      .value-input {
        max-width: 150px;
      }

      .section-footer {
        margin-top: 16px;
      }
    }
  }
}
