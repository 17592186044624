.command-center-page {
  @include page-layout;

  .page-header {
    .page-header-buttons {
      display: flex;
      font-size: 12px;
      font-weight: 600;

      .btn-new {
        color: $--c6-white;
        background-color: $--accent;
      }
    }
  }
}
