.campaign-table-component {
  @include table-format;
  position: relative;
  box-shadow: $card-box-shadow;

  &.loading {
    opacity: 0.5;
  }

  &.hidden {
    display: none;
  }

  > .table-header {
    .table-header-left {
      display: block;

      .table-header-left-inner {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }

      .table-filter-shower {
        margin: 0;
        margin-top: 8px;
      }

      .rs-dropdown-menu {
        min-width: 200px;
      }

      .rs-dropdown-item-panel {
        padding: 2px 12px;
        font-size: 12px;
      }

      .rs-dropdown-item {
        font-size: 11px;

        .rs-dropdown-item-content {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      .rs-dropdown-item-content {
        display: flex;
        align-items: center;

        svg {
          margin-left: 8px;
        }
      }
    }

    .ad-type-select {
      min-width: 140px;

      .ad-type-select__menu {
        z-index: 6;
      }
    }

    .portfolio-select-wrapper,
    .tags-select-wrapper {
      display: flex;
      align-items: center;

      &:hover {
        .select-inclusive-btn {
          border-color: #B3B3B3;
        }
      }
    }

    .portfolio-select,
    .tags-select {
      min-width: 140px;

      .portfolio-select__menu,
      .tags-select__menu {
        z-index: 6;
      }

      .portfolio-select__control,
      .tags-select__control {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .select-inclusive-btn {
      height: 38px;
      border: 1px solid #ccc;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: $--c6-white;
      transition: border-color ease-in-out 100ms;
    }
  }

  > .table-body {
    background-color: #f8f8f8;

    &.sticky {
      padding-top: $table-header-height * 2;

      .content-header,
      .content-footer {
        position: absolute;
        // z-index of right pane is 7
        // so a value less than 7 must be used
        z-index: 6;
      }
    }

    .table-row {
      &.content-header,
      &.content-footer {
        & > .table-col {
          white-space: nowrap;
          cursor: pointer;
          height: $table-header-height;

          &.campaign-status {
            flex-direction: row;
            align-items: center;

            .max-svg,
            .min-svg {
              transform: rotate(45deg);
            }

            .min-svg {
              margin-left: 4px;
            }

            .max-svg {
              margin-left: auto;
            }
          }

          .resize-handle {
            position: absolute;
            display: block;
            border-right: 2px solid transparent;
            top: 0;
            right: 0;
            width: 8px;
            z-index: 1;
            cursor: col-resize;

            &.active {
              border-color: #dddddd;
            }
          }
        }
      }

      .table-col {
        position: relative;
        min-width: 75px;
        width: 10%;
        justify-content: center;
        flex-direction: column;
        background-color: white;

        &.action-column {
          flex-direction: row;
          column-gap: 8px;
          cursor: pointer;

          & > svg {
            margin-top: -5px;
            font-size: 16px;

            &:hover {
              color: #2ca5f4;
            }
          }
        }

        &:nth-child(1) {
          width: 40px;
        }

        &:nth-child(2) {
          align-items: flex-start;
          min-width: 205px;
          flex: 1;
        }

        .action-button-container {
          display: flex;
          position: absolute;
          top: 54px;
          z-index: 99;

          .btn {
            padding-top: .25rem;
            padding-bottom: .25rem;
          }

          .btn + .btn {
            margin-left: 4px;
          }
        }

        .edit-input {
          @include input-format;
          height: 32px;
        }

        .campaign-status {
          display: flex;
          column-gap: 8px;
          font-size: 10px;
          margin-bottom: 4px;

          .status {
            display: flex;
            align-items: center;

            .bullet {
              display: inline-block;
              width: 4px;
              height: 4px;
              margin-right: 4px;
              border-radius: 50%;
            }

            &.on {
              span {
                color: #2EB73C;
              }

              .bullet {
                background: #2EB73C;
              }
            }

            &.off {
              span {
                color: $--accent;
              }

              .bullet {
                background: #DDADAA;
              }
            }
          }

          svg {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }

        .campaign-name {
          width: 100%;
          margin-bottom: 4px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;

          &.disabled {
            pointer-events: none;
          }
        }

        .campaign-detail {
          display: flex;
          font-size: 11px;
          color: $--dark-gray;

          span + span {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.campaign-view-detail {
  width: 100%;
  padding-left: 75px;
  position: relative;

  .loading-text {
    padding: 16px 0;
    font-size: 12px;
    color: $--dark-gray;
    text-align: center;
  }

  .view-row {
    display: flex;
    align-items: center;
    font-size: 11px;
    min-height: 32px;
    min-width: 1000px;

    .view-col {
      width: 12%;
      min-width: 75px;
      padding-left: 10px;
      text-align: center;
      overflow-wrap: anywhere;

      &.action-col {
        display: flex;
        justify-content: center;
        cursor: pointer;

        .magnify-icon {
          margin-left: 4px;
        }

        &:hover {
          color: #2ca5f4;
        }
      }

      .asin-images-record {
        display: flex;
        justify-content: center;
        padding: 4px 0;

        img {
          width: 120px;
          height: 80px;
        }

        .rs-carousel {
          width: 120px;
          height: 80px;
          background: none;
        }

        .number-of-asins {
          position: absolute;
          top: calc(50% - 15px);
          font-size: 30px;
          font-weight: 700;
          color: $--c6-white;
          -webkit-text-stroke: 1px $--c6-black;
          cursor: default;
        }
      }

      .view-show-creative {
        text-decoration: underline;
        color: $--c6-blue;
        cursor: pointer;
      }

    }
    &.view-header {
      font-size: 11px;
      font-weight: 700;
      border-bottom: 1px solid $--light-gray;
    }
  }
}

.history-tooltip {
  .rs-tooltip-inner {
    padding: 8px !important;
    border-radius: 8px;
  }

  .rs-tooltip-arrow {
    top: 50% !important;
  }
}

.thumb-history-modal {
  .rs-modal-content .rs-modal-body {
    display: flex;
    height: 500px;
    flex-direction: column;
  }

  .metric {
    text-align: center;

    strong {
      font-size: 18px;
    }
  }

  .chart-wrapper {
    flex: 1;
  }
}

.update-placements-modal,
.update-biddings-modal,
.update-names-modal,
.add-notes-modal {
  @include modal-format;

  .action-container {
    display: flex;
    align-items: flex-end;
    column-gap: 16px;
    margin-bottom: 16px;

    .action-value-wrapper {
      label {
        display: block;
        margin-bottom: 4px;
        font-weight: 700;
      }

      input {
        @include input-format;
        max-width: 120px;
      }
    }
  }

  .custom-table-component {
    @include table-format;

    .table-header-right {
      align-items: flex-end!important;

      .btn {
        min-width: 120px;
        margin-left: 16px;
      }
    }

    .table-col {
      flex: 1;

      &.col-state {
        text-transform: capitalize;
      }

      > input {
        @include input-format;
      }

      .campaign-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.update-names-modal {
  .custom-table-component {
    .table-header-right {
      flex-direction: column;
      width: 100%;

      .name-suffix-section {
        display: flex;
        width: 100%;

        input {
          @include input-format;
          flex: 1;
        }
      }

      .type-suffix-section {
        display: flex;
        width: 100%;
        margin-top: 8px;
        column-gap: 8px;

        > div {
          min-width: 180px;
        }
      }

      .undo-section {
        display: flex;
        width: 100%;
        margin-top: 8px;

        .btn:first-child {
          margin-left: 0;
        }
      }
    }

    .table-row {
      min-height: 110px;
    }
  }
}

.add-notes-modal {
  .custom-table-component {
    .table-header-right {
      width: 100%;

      .bulk-note-section {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }

    .table-body {
      .table-row {
        min-height: 110px;

        &.content-header {
          min-height: 70px;

          .table-col {
            min-height: 70px;
          }
        }

        .table-col {
          min-height: 110px;

          &.col-campaign {
            max-width: 250px;
          }

          &.col-action {
            max-width: 80px;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
.campaign-note-textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.comparison-data-modal,
.stream-data-modal,
.chart-view-modal {
  .rs-modal-title {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}

.comparison-data-modal {
  .data-content {
    border: 1px solid #ddd;
    border-radius: 8px;
  }
}

.stream-data-modal {
  .stream-content {
    margin-top: 8px;
    overflow: auto;

    .stream-sb-note {
      margin: 8px;
    }

    .section {
      margin-bottom: 32px;
      background: #fff;
      box-shadow: $card-box-shadow;

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 25px 0;

        h4 {
          font-size: 16px;
        }
      }

      .stream-data-container {
        position: relative;
        min-height: 96px;
        padding: 18px 24px;

        .custom-table-component {
          @include table-format;

          .table-col {
            flex: 1;

            &.col-keyword {
              flex: 0 0 160px;
              flex-direction: column;
              align-items: flex-start!important;
              justify-content: center;

              .meta-data {
                margin-top: 4px;
                font-size: 11px;
                color: $--dark-gray;
              }
            }
          }
        }

        .table-target-stream-view {
          @include group-table-format;

          .child-table-wrapper {
            padding-left: 200px;
          }
        }
      }

      .stream-header-wrapper {
        display: flex;
        align-items: center;

        > svg {
          margin-left: 8px;
        }
      }

      .mode-container {
        display: flex;
        align-items: center;

        .date-range-component {
          margin-right: 16px;
        }

        .rs-btn-toggle {
          margin-left: 16px;
        }
      }
    }
  }
}

.chart-view-modal {
  .rs-modal-body {
    .log-view-hint {
      margin-top: 16px;
      text-align: center;
    }
  }

  .chart-area {
    width: 100% !important;
  }

  .section {
    margin-bottom: 32px;
    background: #fff;
    box-shadow: $card-box-shadow;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 25px 0;

      h4 {
        font-size: 16px;
      }
    }

    .table-logs {
      @include table-format;

      > .table-body {
        .table-row:not(.content-header) {
          height: auto;
          min-height: 70px;
          align-items: stretch;

          .table-col {
            height: auto;
          }
        }
      }

      .table-header-right {
        .category-selector,
        .type-selector {
          min-width: 180px;
        }

        .type-selector {
          margin-left: 8px;
        }

        .type-selector + .btn {
          margin-left: 8px;
        }
      }

      .table-col {
        flex: 1;
      }

      .col-type {
        flex: 0 0 200px;
      }

      .col-description {
        flex: 0 0 180px;
      }

      .col-contents {
        padding: 8px 0;
      }

      .col-created-at {
        flex: 0 0 64px;
      }

      .col-action {
        flex: 0 0 120px;
      }
    }
  }
}

.creative-modal {
  @include modal-format;
}
