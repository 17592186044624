.campaign-detail-st-op {
  .tab-content {
    position: relative;

    &.loading {
      opacity: .5;
    }
  }

  .time-saving-filter-contents {
    display: flex;
    align-items: flex-start;
    margin-top: 32px;

    .table-container {
      flex: 1;

      .table-title {
        font-weight: 600;
      }
    }

    .table-container + .table-container {
      margin-left: 16px;
    }
  }

  .table-search-terms {
    @include sortable-table-format;

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 80px;
      }

      &.col-search-term,
      &.col-keyword {
        flex: 2;
        min-width: 120px;

        .contents {
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          cursor: pointer;
        }
      }
    }
  }
}