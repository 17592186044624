@font-face {
  font-family: 'Open Sans';
  src: url('../assets/font/OpenSans/OpenSans-Regular.ttf');
}

body {
  overflow: hidden;
  font-family: 'Open Sans';
  color: $--c6-black;
}

input {
  font-family: 'Open Sans';
}

.flex {
  display: flex;
}

.main {
  // Height of Carbon6 navbar is 48px.
  height: calc(100vh - 48px);
}

.table-pagination {
  flex-wrap: wrap;

  .pagination-right {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.rs-tooltip {
  font-size: 13px !important;

  .rs-tooltip-inner {
    max-width: 480px !important;
    padding: 8px 20px !important;
    background-color: #337AB7 !important;
    text-align: left !important;
    opacity: 0.9;

    ul {
      margin-bottom: 0;
      padding-left: 8px;
    }
  }
}

.rs-tooltip[class*='placement-left'] {
  .rs-tooltip-arrow {
    border-left-color: #337AB7 !important;
  }
}

.rs-tooltip[class*='placement-right'] {
  .rs-tooltip-arrow {
    border-right-color: #337AB7 !important;
  }
}

.rs-tooltip[class*='placement-bottom'] {
  .rs-tooltip-arrow {
    border-bottom-color: #337AB7 !important;
  }
}

.coin-label {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #072257;
  border-radius: 50%;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/img/logo.png");
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.text-center {
  text-align: center;
}

.loader-component {
  position: absolute;
  display: flex;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  width: 64px;
  height: 64px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

#hubspot-messages-iframe-container {
  &.widget-align-right {
    margin-right: 10px;
  }
}

.tag-picker-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: none;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media print {
  body {
    overflow: visible;
  }
  body * {
    visibility: hidden;
  }
  .main-layout {
    .main-content {
      overflow: inherit !important;
    }
  }
  .report-content * {
    visibility: visible;
  }
  button {
    display: none !important;
  }
  .table-pagination {
    display: none !important;
  }
}

@page {
  size: A1;
}
