.product-table-component {
  @include table-format;
  position: relative;
  box-shadow: $card-box-shadow;

  &.loading {
    opacity: 0.5;
  }

  .table-filter-shower {
    margin-left: 16px;
    margin-right: 16px;
  }

  .table-header {
    .table-header-left {
      .download-cog-csv {
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-left: 8px;
      }

      .btn-export-csv {
        margin-left: 8px;
      }

      .rs-dropdown-menu {
        min-width: 200px;
      }

      .rs-dropdown-item-panel {
        padding: 2px 12px;
        font-size: 12px;
      }

      .rs-dropdown-item {
        font-size: 11px;

        .rs-dropdown-item-content {
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }

      .rs-dropdown-item-content {
        display: flex;
        align-items: center;

        svg {
          margin-left: 8px;
        }
      }
    }
  }

  .table-body {
    .table-row {
      &.content-header,
      &.content-footer {
        & > .table-col {
          height: 48px;

          .resize-handle {
            display: block;
            border-right: 2px solid transparent;
            cursor: col-resize;
            position: absolute;
            width: 8px;
            right: 0;
            top: 0;
            z-index: 1;

            &.active {
              border-color: #DDDDDD;
            }
          }
        }

        &.sticky {
          position: absolute;
          // z-index of right pane is dropdown
          // so a value less than 6 must be used
          z-index: 5;
        }
      }

      .table-col {
        position: relative;
        min-width: 75px;
        width: 12%;

        & > input {
          max-width: 100%;
        }

        &:nth-child(1) {
          width: 40px;
        }

        &.product-name {
          display: flex;
          min-width: 240px;
          padding-right: 16px;

          img {
            width: 48px;
            height: 48px;
            margin-right: 8px;
          }

          .product-info {
            display: flex;
            flex-direction: column;
            width: calc(100% - 56px);

            .test-on,
            .test-off {
              font-weight: 700;
              font-size: 10px;
            }

            .test-on {
              color: #2EB73C;
            }

            .test-off {
              color: $--accent;
            }

            .product-meta,
            a {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .product-meta {
              font-size: 11px;
              color: $--gray;
            }
          }

          // img {
          //   width: 20px;
          //   height: 20px;
          //   margin-left: 10px;

          //   &:hover {
          //     opacity: 0.7;
          //   }
          // }

          .max-svg, .min-svg {
            transform: rotate(45deg);
          }

          .min-svg {
            margin-left: 4px;
          }

          .max-svg {
            margin-left: auto;
          }
        }

        &.col-cog {
          input {
            @include input-format;
            height: 32px;
          }

          .cog-btn-container {
            position: absolute;
            display: flex;
            top: 54px;
            z-index: 99;

            .btn {
              padding-top: .25rem;
              padding-bottom: .25rem;
            }

            .btn + .btn {
              margin-left: 4px;
            }
          }
        }

        &.organic-sales-ratio,
        &.organic-sales,
        &.active-campaigns {
          min-width: 90px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          & > span:last-child {
            border-top: 1px solid $--gray;
          }
        }

        &.ad-spend-margin {
          min-width: 105px;
          white-space: nowrap;
        }

        &.break-even {
          min-width: 100px;
        }
      }
    }

    .product-detail {
      width: 100%;
      padding: 0 50px;
      position: relative;
      overflow-x: scroll;

      .keyword-row {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 11px;

        .keyword-col {
          width: 20%;
          min-width: 75px;
          padding-left: 10px;
          overflow-wrap: anywhere;

          &:nth-child(1) {
            min-width: 120px;
          }
        }

        &.keyword-header {
          font-size: 12px;
          font-weight: 600;
          align-items: flex-end;
          .keyword-col {
            border-bottom: 1px solid $--light-gray;
          }
        }
      }

      .keyword-no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;
      }
    }
  }
}