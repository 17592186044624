.bulk-editor-page {
  @include page-layout;

  .bulk-engine-container {
    &.loading {
      opacity: 0.5;
    }

    .module-description {
      margin-top: 16px;
      margin-left: 24px;
      font-size: 18px;
      font-weight: 700;
    }

    .module-selector-container {
      display: flex;
      align-items: center;
      margin-top: 16px;
      padding: 8px 24px;

      .module-selector-left {
        display: flex;
        flex: 1;
        align-items: center;
        column-gap: 16px;

        .rs-dropdown-item-content {
          display: flex;
          align-items: center;

          svg {
            margin-left: 8px;
          }
        }

        > label {
          font-weight: 700;
        }

        > input {
          @include input-format;
          width: auto;
          max-width: 72px;
          height: 36px;
        }

        .rs-dropdown-toggle {
          .rs-btn {
            padding-right: 32px;
          }
        }

        .toggle-caret {
          position: absolute;
          top: 8px;
          right: 12px;
          color: #fff;

          &::before {
            content: "\ea08"!important;
            font-family: "rsuite-icon-font"!important;
          }
        }
      }
    }

    .filter-container {
      display: flex;
      align-items: center;
      margin: 16px;
      column-gap: 16px;

      .checkbox-wrapper {
        display: flex;
        align-items: center;

        > svg {
          margin-left: 8px;
        }
      }

      .select-wrapper {
        display: flex;
        align-items: center;

        > span {
          margin-right: 8px;
          font-weight: 700;
        }

        .word-count-selector__menu,
        .match-type-selector__menu,
        .status-selector__menu,
        .st-filter-selector__menu,
        .ad-type-selector__menu {
          // z-index of date picker is 5.
          z-index: 6;
        }
      }

      .search-wrapper {
        > label {
          font-weight: 700;
        }

        > input {
          @include input-format;
          width: auto;
          max-width: 72px;
          height: 36px;
        }

        label + input {
          margin-left: 8px;
        }
      }

      .group-by-selector__menu {
        // z-index of search box is 4.
        z-index: 5;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 4px;
      }
    }

    .table-jobs {
      @include table-format;
      margin-top: 16px;

      .table-header-right {
        flex: 1;
        justify-content: space-between;
      }

      .table-col {
        flex: 1;

        > svg {
          margin-left: 4px;
        }

        .rs-btn + .rs-btn {
          margin-left: 8px;
        }
      }
    }
  }

  .campaign-selection-state {
    margin: 8px 16px 0;
    padding: 8px 16px;
    border: 1px solid $--light-gray;
    border-radius: 4px;

    > a {
      margin-left: 16px;
    }
  }

  .bulk-result-container {
    .table-filter-shower {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}

.popup-campaign-names {
  max-height: 200px;
  overflow-y: auto;

  a {
    color: #fff;
  }
}
