.sb-campaign-creator {
  .sb-bid-section {
    .auto-bidding-note {
      margin-top: 4px;
      margin-left: 32px;
      font-size: 12px;
      color: $--gray;
    }

    .custom-bid-adjustment-section {
      margin-top: 16px;

      > label {
        font-weight: 700;
      }

      .adjustment-value {
        display: flex;
        margin-top: 4px;
        align-items: center;

        > input {
          @include input-format;
          max-width: 160px;
          margin: 0 8px;
        }
      }
    }
  }

  .product-selector {
    max-width: 360px;

    .product-option {
      display: flex!important;
      min-width: 0;
      align-items: center;

      img,
      .placeholder-image {
        width: 32px;
        height: 32px;
        border-radius: 4px;
      }

      .placeholder-image {
        line-height: 32px;
      }

      .product-info {
        min-width: 0;
        margin-left: 16px;

        .product-name {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .product-sku {
          margin-top: 4px;
          color: $--gray;
          font-size: 12px;
        }
      }
    }
  }
}

.sb-brand-logo-modal {
  &.loading {
    opacity: 0.5;
  }

  .rs-modal-body {
    display: flex;
    flex-wrap: wrap;
    min-height: 120px;
  }

  .logo-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .logo-wrapper {
    width: 33.3%;
    padding: 8px;

    .logo-contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      border: 1px solid $--light-gray;

      img {
        max-width: 96px;
      }

      .logo-info {
        margin-top: 8px;
        margin-bottom: 12px;

        .logo-name {
          text-align: center;
          word-wrap: break-word;
        }

        .logo-dimension {
          margin-top: 4px;
          font-size: 12px;
          color: $--gray;
          text-align: center;
        }
      }
    }
  }
}

.sb-product-ordering-modal {
  @include modal-format;

  .rs-modal-body {
    display: flex;
  }

  .column-container {
    flex: 1;

    .product-wrapper {
      display: flex;
      align-items: center;
      padding: 16px;
      border: 1px solid $--light-gray;

      img {
        max-width: 72px;
      }

      .product-info {
        margin: 0 16px;

        .product-name {
          margin-bottom: 8px;
        }

        .product-detail {
          span {
            font-size: 13px;
            color: $--gray;
          }

          span + span {
            margin-left: 32px;
          }
        }
      }
    }

    .product-wrapper + .product-wrapper {
      margin-top: 8px;
    }
  }

  .column-container + .column-container {
    margin-left: 16px;
  }
}
