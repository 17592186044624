.call-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px 16px;
  padding: 4px 48px;
  gap: 48px;
  background: linear-gradient(90.7deg, #0E2154 3.27%, #441C52 59.94%, #0F2154 97.03%);
  box-shadow: 0px 2px 9px rgba(0, 83, 215, 0.15), 2px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media (max-width: 576px) {
    margin-left: 8px;
    margin-right: 8px;
  }

  .description-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;

    > svg {
      margin-bottom: 8px;
    }
  }

  .call-text {
    display: flex;
    flex: none;
    order: 1;
    flex-grow: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    color: #fff;

    .call-name {
      font-size: 24px;
      font-weight: 600;
    }

    .call-description {
      font-size: 16px;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 8px;
    width: 160px;
    height: 42px;
    background: #0C1D4F;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 30px rgba(215, 67, 95, 0.5), 0px 0px 20px rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: #fff;
    flex: none;
    order: 1;
    flex-grow: 0;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.notification-box {
  display: flex;
  align-items: center;
  margin: 0 32px 16px;
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid $--accent;
  border-radius: .25rem;
  box-shadow: 0px 3px 18px rgba(186, 186, 186, 0.44);

  @media (max-width: 576px) {
    margin-left: 8px;
    margin-right: 8px;
  }

  .call-text {
    flex: 1;
    margin-left: 16px;

    .call-name {
      font-size: 16px;
      font-weight: 600;
      color: $--c6-black;
    }

    .call-description {
      margin-top: 4px;
      color: #000;
    }
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }

  a.disabled {
    pointer-events: none;
    opacity: .65;
    box-shadow: none;
  }

  a + a {
    margin-left: 16px;
  }
}
