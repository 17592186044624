.step-container {
  margin-bottom: 8px;

  .step-wrapper {
    display: inline-flex;
    align-items: center;
    color: $--gray;

    &.active {
      color: $--dark-gray;
      font-weight: 700;
    }

    > svg {
      margin-left: 8px;
    }
  }

  .step-wrapper + .step-wrapper {
    margin-left: 8px;

    &::before {
      content: ' > ';
      margin-right: 8px;
      color: $--light-gray;
    }
  }
}
