.table-filter-modal {
  .filter-selector {
    margin-bottom: 16px;
  }

  .filter-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    & > span {
      margin-bottom: 4px;
    }

    .checkbox-wrapper {
      display: flex;

      > svg {
        margin-left: 8px;
      }
    }

    .filter-row-child {
      display: flex;
      justify-content: space-between;

      input {
        @include input-format;
      }

      input + input {
        margin-left: 16px;
      }
    }
  }
}

.filter-save-modal {
  input {
    @include input-format;
  }
}
