@mixin rule-manager($manager-width) {
  @include right-pane();
  width: $manager-width;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;

  .loader-component.rule-loader {
    position: fixed;
    left: auto;
    right: $manager-width / 2 - 32px;
  }

  .top-container {
    position: sticky;
    display: block;
    top: 0;
    margin: 0 -24px;
    padding: 20px 24px 8px;
    background-color: #fff;
    // z-index of react-select is 1.
    // z-index of date picker is 5.
    z-index: 6;

    .pane-header {
      .left-column {
        display: flex;
        align-items: center;

        .rs-btn-toggle {
          margin-left: 8px;
          background-color: #ddadaa;

          &.rs-btn-toggle-checked {
            background-color: #32b83f;
          }
        }
      }

      .right-column {
        display: flex;
        align-items: center;

        .timezone-wrapper,
        .lookback-wrapper {
          display: flex;
          align-items: center;
          margin-right: 16px;

          label {
            margin-right: 4px;
          }
        }

        .lookback-wrapper {
          margin-left: 8px;

          > svg {
            margin-right: 4px;
          }
        }

        .timezone-wrapper {
          .timezone-selector {
            min-width: 180px;
          }
        }
      }
    }

    .campaign-info {
      margin-top: 16px;

      .template-name {
        margin-left: 16px;
        font-size: 12px;
      }

      .template-remove {
        margin-left: 16px;
        font-size: 12px;
        color: $--red;
      }

      .campaign-list {
        margin-top: 8px;

        .campaign-wrapper {
          display: inline-flex;
          align-items: center;
          margin: 4px;
          padding: 4px 8px;
          background-color: #f8f8f8;
          border-radius: 4px;
          font-size: 12px;

          .remove-campaign-button {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            font-size: 16px;
            line-height: 16px;
            color: $--gray;
            text-align: center;
            cursor: pointer;

            &:hover {
              color: $--c6-black;
            }
          }
        }
      }
    }

    .save-instruction {
      margin-top: 16px;
      color: $--red;
    }

    .template-name-wrapper {
      position: relative;
      display: inline-block;
      margin-top: 16px;
      margin-bottom: 16px;

      input {
        padding-right: 24px;
        border: none;
        border-bottom: 1px dashed $--gray;
        font-size: 18px;
        color: $--c6-black;
      }

      > svg {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -7px;
      }
    }
  }

  .pane-body {
    .rule-section {
      margin-top: 16px;

      .section-header  {
        font-size: 16px;
        font-weight: 700;
        color: $--c6-black;
      }
    }

    .table-campaigns-apply {
      @include table-format;

      .table-header-right {
        .ad-type-selector {
          min-width: 180px;
        }
      }

      .table-col {
        flex: 1;

        &.col-type {
          flex: 0 0 150px;
        }
      }

      .table-pagination {
        @include pagination-compact;
      }
    }
  }

  .pane-footer {
    position: sticky;
    display: flex;
    bottom: 0;
    margin: 16px -24px 0;
    padding: 20px 24px;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: #fff;
    border-top: 1px solid $--light-gray;
    z-index: 5;

    .btn {
      min-width: 120px;
    }

    .rs-dropdown + .btn,
    .btn + .btn {
      margin-left: 16px;
    }
  }
}

@mixin rule-template-selector() {
  .template-option {
    display: flex!important;
    align-items: center;
    justify-content: space-between;

    &.selected {
      a {
        color: $--green;
      }
    }

    a {
      font-size: 12px;
    }

    .action-links {
      a + a {
        margin-left: 8px;
      }
    }
  }

  .template-option-label {
    .action-links {
      a {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }

  .note {
    margin-top: 4px;
    font-size: 12px;
    color: $--gray;
  }

  .template-details {
    margin-top: 16px;
    padding: 8px;
    border: 1px solid $--light-gray;
    border-radius: 4px;

    .template-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      .template-name {
        font-size: 16px;
        font-weight: 700;
      }

      .action-buttons {
        .remove-link {
          margin-left: 8px;
          color: $--red;
        }
      }
    }

    .timezone-setting {
      margin-bottom: 6px;
    }

    .slot-rules + .slot-rules {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid $--light-gray;
    }

    .rule-details {
      margin-top: 4px;
    }
  }
}
