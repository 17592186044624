.campaign-detail-component {
  @include page-layout;

  .page-header {
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 24px;

    .page-title {
      display: flex;
      align-items: center;

      .task-icon {
        width: 20px;
        margin-left: 16px;
        cursor: pointer;
      }

      .campaign-selector-container {
        position: relative;

        .toggle-button {
          display: inline-flex;
          align-items: center;
          font-size: 24px;
          color: $--dark-gray;
          border-bottom: 1px dashed $--gray;

          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }

          svg {
            margin-left: 8px;
            font-size: 12px;
          }
        }

        .campaign-name-wrapper {
          display: flex;
          align-items: center;
          column-gap: 16px;

          .status {
            display: inline-flex;
            align-items: center;
            margin-left: 16px;
            font-size: 14px;

            &.on {
              color: #2EB73C;
            }

            &.off {
              color: $--accent;
            }
          }

          .toggle-button + .status {
            margin-left: 32px;
          }

          svg {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }

        .selector-contents {
          position: absolute;
          width: 320px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: $card-box-shadow;
          font-size: 14px;
          // z-index of date range picker popup is 7,
          z-index: 8;

          .table-campaign-selector {
            @include table-format;

            .table-row {
              &.selected {
                background-color: $--c6-blue;

                &:hover {
                  background-color: $--c6-blue;
                }

                a {
                  color: #fff;

                  .campaign-detail {
                    color: $--light-gray;
                  }
                }
              }
            }

            .table-col {
              flex: 1;
              width: 100%;
              padding-right: 28px!important;

              a {
                display: block;
                width: 100%;

                &:hover,
                &:focus,
                &:active {
                  text-decoration: none;
                }

                .campaign-name {
                  display: -webkit-box;
                  margin-bottom: 4px;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }

                .campaign-detail {
                  font-size: 11px;
                  color: $--gray;

                  span + span {
                    margin-left: 8px;
                  }
                }
              }
            }

            .pagination-left {
              font-size: 14px;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      .campaign-name-wrapper {
        flex-wrap: wrap;
        padding-bottom: 4px;
        border-bottom: 1px dashed #979797;

        .toggle-button {
          width: 100%;
          margin-bottom: 4px;
          border-bottom: none;
        }

        .status {
          margin-left: auto !important;
        }
      }
    }
  }

  .campaign-detail-tab {
    margin-top: 8px;
    padding: 16px 24px;
    background: #fff;
    box-shadow: $card-box-shadow;
    overflow: auto;

    .adgroup-selector {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .btn {
        margin-left: 8px;
        margin-bottom: 4px;
      }
    }

    .filter-container {
      display: flex;
      align-items: center;
      margin-top: 16px;

      .checkbox-wrapper {
        display: flex;
        align-items: center;

        > svg {
          margin-left: 8px;
        }
      }

      .select-wrapper {
        display: flex;
        align-items: center;

        > span {
          margin-right: 8px;
          font-weight: 700;
        }

        .match-type-selector__menu {
          // z-index of date picker is 5.
          z-index: 6;
        }
      }

      .checkbox-wrapper + .checkbox-wrapper,
      .checkbox-wrapper + .select-wrapper {
        margin-left: 16px;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;

        .checkbox-wrapper + .checkbox-wrapper,
        .checkbox-wrapper + .select-wrapper {
          margin-left: 0 !important;
          margin-top: 4px;
        }
      }
    }
  }

  .page-tabs {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: $--gray;
    overflow-x: auto;

    .page-tab {
      margin-right: 28px;
      padding-bottom: 13px;
      background-color: transparent;
      border-bottom: 2px solid transparent;
      white-space: nowrap;
      &.selected, &:hover {
        color: $--c6-black;
        border-bottom: 2px solid $--c6-black;
      }
    }

  }

  .table-action {
    padding: 10px 25px;
    display: flex;
    .btn {
      font-size: 13px;
      margin-right: 10px;
      border-radius: 4px;
      padding: 7px 22px;
    }
  }

  .tab-info {
    display: flex;
    align-items: center;

    .tab-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;

      > svg {
        margin-left: 8px;
      }
    }

    .tab-description {
      margin-left: 32px;
      font-size: 14px;
    }

    .tutorial-link {
      margin-left: auto;
    }

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      .tab-description {
        order: 1;
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .tab-list {
    margin-top: 16px;

    .tab {
      display: inline-flex;
      align-items: center;
      margin-right: 16px;
      padding-bottom: 4px;
      background-color: transparent;
      border-bottom: 2px solid white;
      color: $--gray;

      &.selected,
      &:hover {
        color: $--c6-black;
        border-bottom-color: $--c6-black;
      }

      > svg {
        margin-left: 8px;
      }
    }
  }

  .acos-container {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    min-width: max-content;
    align-self: flex-start;

    span {
      margin-right: 8px;
      font-weight: 600;
      color: $--c6-blue;
    }

    input {
      @include input-format;
      width: 72px;
    }
  }

  .note-widget {
    margin-bottom: 24px;
    min-height: 90px;
    background: #fff1c9;
    border-radius: 4px;
    padding: 10px 20px;

    .note-header {
      display: flex;
      justify-content: space-between;

      .note-header-left {
        display: flex;
        align-items: center;

        span {
          font-size: 18px;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }

    .rs-btn {
      background: none;
    }
  }

  @media (max-width: 768px) {
    .adgroup-selector {
      flex-wrap: wrap;
    }

    .adgroup-content {
      order: 1;
      width: 100%;
      margin-top: 10px;
    }

    .acos-container {
      margin-left: 0 !important;
    }
  }

  @media (max-width: 375px) {
    .adgroup-content {
      display: flex;
      flex-direction: column;

      button {
        margin-left: 0 !important;
      }
    }
  }
}

.modal-add-negatives {
  .top-section,
  .bottom-section {
    display: flex;

    .section-container {
      flex: 1;
      margin: 16px 0;

      .section-title {
        margin-bottom: 8px;
        font-weight: 600;
      }

      > .checkbox-component {
        margin-left: 8px;
        margin-bottom: 8px;
      }
    }

    .section-container + .section-container {
      margin-left: 16px;
    }
  }

  .bottom-section {
    margin-top: 16px;
  }

  .table-negatives,
  .table-adgroups {
    @include table-format;
    @include compact-header-table-format;

    .table-col {
      flex: 1;
    }
  }
}

.modal-add-negative-asins {
  .section-row {
    display: flex;

    .section-container {
      flex: 1;
      margin: 16px 0;

      .section-title {
        margin-bottom: 8px;
        font-weight: 600;
      }
    }

    .section-container + .section-container {
      margin-left: 16px;
    }
  }

  .table-negatives,
  .table-adgroups {
    @include table-format;
    @include compact-header-table-format;

    .table-col {
      flex: 1;
    }
  }
}
