.campaign-detail-sku-op {
  .table-skus {
    @include sortable-table-format;

    .table-row {
      &.bg-red {
        .table-col {
          background-color: $--accent;
          color: #fff;
        }

        &:hover {
          .table-col {
            color: $--gray;
          }
        }
      }
    }

    .table-col {
      flex: 1;

      &:not(.col-check) {
        min-width: 80px;
      }

      &.col-state {
        text-transform: capitalize;
      }

      &.col-sku {
        flex: 2;
      }

      img {
        max-height: 48px;
      }
    }
  }
}
