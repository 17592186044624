.table-collection {
  background: $--c6-white;
  border-radius: 4px;
  margin-top: 16px;
  min-height: 500px;

  .table-tabs {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0;
    border-bottom: 1px solid $--light-gray;

    .table-tab-left {
      display: flex;

      .table-tab {
        margin-right: 16px;
        padding-bottom: 8px;
        font-size: 15px;
        background-color: transparent;
        color: $--gray;

        &.selected,
        &:hover {
          border-bottom: 2px solid $--c6-black;
          color: $--c6-black;
        }
      }
    }

    .table-tab-right {
      display: flex;
      align-items: center;
      column-gap: 16px;
      margin-bottom: 8px;

      svg {
        width: 30px;
        height: 30px;
        padding: 6px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: $--c6-blue;
          stroke: $--c6-white;

          path {
            stroke: $--c6-white;
          }
        }
      }
    }
  }
}