.add-to-portfolio-pane {
  @include right-pane();
  width: 720px;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;

  .top-container {
    position: sticky;
    display: block;
    top: 0;
    margin: 0 -24px;
    padding: 16px 24px;
    background-color: $--c6-purple;
    // z-index of react-select is 1.
    z-index: 2;

    .pane-header {
      .left-column {
        display: flex;
        align-items: center;

        .pane-title {
          font-size: 18px;
          color: $--c6-white;
        }
      }

      .right-column {
        display: flex;
        align-items: center;

        .close-icon {
          margin-left: 16px;
          font-size: 30px;
          color: $--gray;
          cursor: pointer;

          &:hover {
            color: $--c6-white;
          }
        }
      }
    }
  }

  .pane-body {
    padding: 16px;

    &.loading {
      opacity: 0.5;
    }

    .section-header {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;

      em {
        display: block;
        margin-top: 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .section-body {
      padding: 16px;
      border: 1px solid $--light-gray;
      border-radius: 8px;

      .list-container {
        max-height: 200px;
        margin-top: 16px;
        overflow-y: auto;

        .list-item {
          display: flex;
          justify-content: space-between;

          .close-icon {
            font-size: 18px;
            color: $--gray;
            cursor: pointer;

            &:hover {
              color: $--dark-gray;
            }
          }
        }

        .list-item + .list-item {
          margin-top: 8px;
        }
      }

      > input {
        @include input-format;
      }

      .save-error {
        margin-top: 8px;
        color: $--red;
      }
    }

    .section-footer {
      position: sticky;
      display: flex;
      bottom: 0;
      margin: 0 -24px;
      padding: 20px 24px;
      justify-content: center;
      flex-wrap: wrap;
      background-color: #fff;
      column-gap: 16px;

      .btn {
        min-width: 120px;
      }

      .btn-red {
        color: #fff;
        background-color: #dd1818;
        background-image: none;
        border-color: #b41010;
      }

      .btn-gray {
        color: #fff;
        background-color: #706c6c;
        background-image: none;
        border-color: #272525;
      }
    }
  }
}
